// Library methods
import { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

// MUI Components
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Utilities
// import { localizeDate } from "../../../../utils/localeHelper";
import { calculateAge } from "../../../../utils/examReportsHelper";
import CustomCheckbox from "../../../../components/UI/CustomCheckbox";
import EditButton from "../../../../components/UI/EditButton";
import { getLocalTime } from "../../../../utils/dateHelper";

const PatientCard = forwardRef(
  (
    {
      row,
      isSelected,
      isActive,
      headCells,
      handleCheckboxClick,
      handleEditClick,
      handleCardClick,
    },
    ref
  ) => {
    // internationalization
    const { t } = useTranslation();
    const sexTitles = {
      Male: t("gender_male"),
      Female: t("gender_female"),
      Undefined: t("word_na"),
    };

    // Translate language property in the patient to specified language.
    const lang = row?.language
      ? "language_" +
        row.language.charAt(0).toLowerCase() +
        row.language.slice(1)
      : "-";

    const nextScheduledExamDate = useMemo(() => {
      if (row?.nextScheduledExamDate)
        return getLocalTime(row.nextScheduledExamDate);
      return "-";
    }, [row]);

    // sx specific are for small mobile screens
    return (
      <Card
        elevation={0}
        key={row.id}
        sx={{
          marginBottom: 2,
          borderRadius: 2,
          backgroundColor:
            isActive === row.id
              ? "#202338"
              : !isSelected
                ? "#282C3C"
                : "#E2772E",
          color: "white",
          opacity: isActive === row.id ? "1" : !isSelected ? "0.4" : "1",
          height: { xs: 89, xxs: 52, sm: 52 },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={(event) => handleCardClick(event, row.id)}
        ref={ref}
      >
        <Grid
          container
          // px={1}
          py={2}
          sx={{
            display: { xs: "grid", xxs: "flex", sm: "flex" },
            gridTemplateColumns: {
              xs: "10% 45% 35% 10%",
              xxs: "none",
              sm: "none",
            },
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={1}>
            <CustomCheckbox
              checkedFill={isActive === row.id ? "white" : "#E2772E"}
              checkedStroke={isActive === row.id ? "#202338" : "white"}
              checked={isSelected}
              onClick={(event) => {
                event.stopPropagation();
                handleCheckboxClick(event, row.id);
              }}
            />
          </Grid>
          <Grid
            container
            item
            xs={10}
            display={{ sx: "block", xxs: "flex", sm: "flex" }}
            flexDirection={{ xxs: "space-between", sm: "space-between" }}
          >
            <Grid item xs={12} sm={headCells[0].gridSize}>
              <Typography
                sx={{
                  display: { xs: "block", xxs: "none", sm: "none" },
                  fontSize: { xs: "12px", sm: "12px", md: "16px", lg: "1rem" },
                }}
              >
                <b>{headCells[0].label}:</b> {row.firstName} {row.lastName}
              </Typography>
              <Typography
                noWrap
                sx={{
                  display: { xs: "none", xxs: "block", sm: "block" },
                  fontSize: { xs: "12px", sm: "12px", md: "16px", lg: "1rem" },
                  fontWeight: "bold",
                }}
              >
                {row.firstName} {row.lastName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={headCells[1].gridSize}>
              <Typography
                sx={{
                  display: { xs: "block", xxs: "none", sm: "none" },
                  fontSize: { xs: "12px", sm: "12px", md: "16px", lg: "1rem" },
                }}
              >
                <b>{headCells[1].label}:</b>{" "}
                {row?.age ?? calculateAge(row.dateOfBirth)}
              </Typography>
              <Typography
                noWrap
                sx={{
                  display: { xs: "none", xxs: "block", sm: "block" },
                  fontSize: { xs: "12px", sm: "12px", md: "1rem" },
                }}
              >
                {row?.age ?? calculateAge(row.dateOfBirth)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={headCells[2].gridSize}>
              <Typography
                sx={{
                  display: { xs: "block", xxs: "none", sm: "none" },
                  fontSize: { xs: "12px", sm: "12px", md: "16px", lg: "1rem" },
                }}
              >
                <b>{headCells[2].label}:</b> {sexTitles[row.sex]}
              </Typography>
              <Typography
                noWrap
                sx={{
                  display: { xs: "none", xxs: "block", sm: "block" },
                  fontSize: { xs: "12px", sm: "12px", md: "16px", lg: "1rem" },
                }}
              >
                {sexTitles[row.sex]}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={headCells[3].gridSize}>
              <Typography
                sx={{
                  display: { xs: "block", xxs: "none", sm: "none" },
                  fontSize: { xs: "12px", sm: "12px", md: "16px", lg: "1rem" },
                }}
              >
                <b>{headCells[3].label}:</b> {row?.language ?? "-"}
              </Typography>
              <Typography
                noWrap
                sx={{
                  display: { xs: "none", xxs: "block", sm: "block" },
                  fontSize: { xs: "12px", sm: "12px", md: "16px", lg: "1rem" },
                  width: { xs: "100%", sm: "100%", md: "75%", lg: "100%" },
                }}
              >
                {t(lang)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={headCells[4].gridSize}>
              <Typography
                sx={{
                  display: { xs: "block", xxs: "none", sm: "none" },
                  fontSize: { xs: "12px", sm: "12px", md: "16px", lg: "1rem" },
                }}
              >
                <b>{headCells[4].label}:</b> {nextScheduledExamDate}
              </Typography>
              <Typography
                noWrap
                sx={{
                  display: { xs: "none", xxs: "block", sm: "block" },
                  fontSize: { xs: "12px", sm: "12px", md: "16px", lg: "1rem" },
                  textAlign:
                    nextScheduledExamDate === "-" ? "center" : undefined,
                }}
              >
                {nextScheduledExamDate}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <EditButton handleEditClick={() => handleEditClick(row)} />
          </Grid>
        </Grid>
      </Card>
    );
  }
);

export default PatientCard;
