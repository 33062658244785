const Circle = ({ size = 10 }) => (
  <svg
    width={size}
    height={size}
    style={{ display: "block", verticalAlign: "middle" }}
  >
    <circle
      cx={size / 2}
      cy={size / 2}
      r={size / 2.5}
      stroke="black"
      strokeWidth="0.5"
      fill="white"
    />
  </svg>
);

export default Circle;
