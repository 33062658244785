import axios from "axios";
import {
  config,
  injectBearer,
  isResponseValid,
  pdfConfig,
  webServiceUrl,
} from "../../utils/servicesHelper";

const ErrorMessages = {
  ReportInfoError: "Report info Error",
  ReportFileGenrationError: "Failed to generate ",
};
const AllFileTypes = {
  pdf: "pdf",
  dicom: "dicom",
};
const addExam = async (accessToken, clinicId, patientId, ExamData) =>
  axios
    .post(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams`,
      ExamData,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const getDashboard = async (accessToken, clinicId, params, signal) =>
  axios
    .get(`${webServiceUrl}/clinics/${clinicId}/dashboard`, {
      headers: { Authorization: injectBearer(accessToken) },
      params,
      signal: signal,
    })
    .then((response) => {
      const dashboard = isResponseValid(response);
      return dashboard;
    })
    .catch((error) => {
      throw error;
    });

const getExams = async (accessToken, clinicId, patientId, signal) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams`,
      config(accessToken, signal)
    )
    .then((response) => {
      const exams = isResponseValid(response);
      return exams;
    })
    .catch((error) => {
      throw error;
    });

const getExamById = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  signal,
  isPdf = null
) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}`,
      isPdf ? pdfConfig(signal) : config(accessToken, signal)
    )
    .then((response) => {
      const exam = isResponseValid(response);
      return exam;
    })
    .catch((error) => {
      throw error;
    });

const getExamSectionById = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  sectionId,
  examFieldType
) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/sections/${examFieldType}/${sectionId}`,
      config(accessToken)
    )
    .then((response) => {
      const sections = isResponseValid(response);
      return sections;
    })
    .catch((error) => {
      throw error;
    });

const getSubmisionData = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  sectionId,
  signal,
  isPdf = null
) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/sections/${sectionId}/submission`,
      isPdf ? pdfConfig(signal) : config(accessToken, signal)
    )
    .then((response) => {
      const report = isResponseValid(response);
      return report;
    })
    .catch((error) => {
      throw error;
    });

const getAccessInfo = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  fileFormat = "pdf",
  signal
) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/file-management/${fileFormat}`,
      config(accessToken, signal)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const getReportInfo = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  signal
) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/report-info`,
      config(accessToken, signal)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw new Error(ErrorMessages.ReportInfoError);
    });

const generateReportFile = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  fileFormat = "pdf",
  signal
) =>
  axios
    .post(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/regenerate-report/${fileFormat}`,
      null,
      config(accessToken, signal)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw new Error(ErrorMessages.ReportFileGenrationError + fileFormat);
    });

const getCommentsReport = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  sectionId,
  signal,
  isPdf = null
) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/sections/${sectionId}/comments`,
      isPdf ? pdfConfig(signal) : config(accessToken, signal)
    )
    .then((response) => {
      const report = isResponseValid(response);
      return report;
    })
    .catch((error) => {
      throw error;
    });

const addExamSection = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  ExamData,
  examFieldType
) =>
  axios
    .post(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/sections/${examFieldType}`,
      ExamData,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const deleteExam = async (accessToken, clinicId, patientId, examId) =>
  axios
    .delete(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}`,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const deleteExamSection = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  sectionId,
  examFieldType
) =>
  axios
    .delete(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/sections/${examFieldType}/${sectionId}`,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const updateExam = async (accessToken, clinicId, patientId, examId, examData) =>
  axios
    .put(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}`,
      examData,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const updateExamSection = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  sectionId,
  examData,
  examFieldType
) =>
  axios
    .put(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/sections/${examFieldType}/${sectionId}`,
      examData,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

/**
 * commentData: [{
 *    "content": string,
 *    "conditions": `[string]`,
 *    "otherConditions": string
 * }]
 */
const addCommentReport = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  sectionId,
  commentData
) =>
  axios
    .post(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/sections/${sectionId}/comments`,
      commentData,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const getTodayPatientCount = async (accessToken, clinicId) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/ScheduledPatientsCount`,
      config(accessToken)
    )
    .then((response) => {
      const report = isResponseValid(response);
      return report;
    })
    .catch((error) => {
      throw error;
    });

export {
  addExam,
  getDashboard,
  getExams,
  getExamById,
  getExamSectionById,
  getSubmisionData,
  getAccessInfo,
  getReportInfo,
  getCommentsReport,
  generateReportFile,
  addExamSection,
  deleteExam,
  deleteExamSection,
  updateExam,
  updateExamSection,
  addCommentReport,
  getTodayPatientCount,
  ErrorMessages,
  AllFileTypes,
};
