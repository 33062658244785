import React, { useMemo, useRef, useState } from "react";
import {
  CustomTextFieldOutlined,
  CustomTextFieldOutlinedInputProps,
} from "../../../styles/muiStylesHelper";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const SearchBox = ({
  data = [],
  state,
  setState,
  propertyBit = 0,
  label = null,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [isListVisible, setIsListVisible] = useState(false);
  const listRef = useRef(null); // Reference to the list container
  const stateRef = useRef(null);

  const wrapperStyle = {
    0: "px-0 py-0",
    1: "px-2 py-8",
    2: "px-0 py-2 pt-4",
  };
  const filteredItems = data.filter((item) =>
    propertyBit === 0 && typeof item?.name === "string"
      ? t(item.name).toLowerCase().includes(searchTerm.toLowerCase())
      : item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const valueOfSearchBox = useMemo(() => {
    // Translate language property in the patient to specified language.
    const val =
      propertyBit === 0
        ? state?.name && state.name !== ""
          ? t(state.name)
          : searchTerm
        : propertyBit % 2 === 0
          ? state?.name
          : propertyBit === 1 && state?.name && state?.utcOffset
            ? `${state.name} (${state.utcOffset})`
            : state && state?.name !== ""
              ? state?.name
              : searchTerm;

    return val;
  }, [propertyBit, searchTerm, state, t]);

  const getDisplayedProperty = (item) => {
    const propertyDic = {
      0: t(item?.name),
      1: `${item?.name} (${item?.utcOffset})`,
    };
    return propertyDic[propertyBit % 2];
  };

  const handleItemClick = (item) => {
    // Clear searchTerm to prevent conflict
    setSearchTerm("");
    stateRef.current = item?.name;
    setState(item);
    // Hide the list after selection
    setIsListVisible(false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    setState(propertyBit % 2 ? { name: "", utcOffset: "" } : "");
    stateRef.current = value;
    // Show the list for new searchTerm
    setIsListVisible(true);
  };

  const handleInputFocus = () => {
    setIsListVisible(true);
  };

  const handleInputBlur = () => {
    if (stateRef.current) {
      const corresponding = data.filter(
        (datum) => datum.name === stateRef.current
      );
      setState(
        corresponding?.[0] ?? {
          name: stateRef?.current,
          val: stateRef?.current,
        }
      );
    }
    // Delay hiding the list to check if the mouse is over it
    setTimeout(() => {
      if (!listRef.current?.contains(document.activeElement)) {
        setIsListVisible(false);
      }
    }, 100);
  };

  const handleMouseDown = (e, item) => {
    // Prevent the input from losing focus
    e.preventDefault();
    handleItemClick(item);
  };

  return (
    <div className="w-full max-w-screen-xl">
      <div className={`flex justify-center ${wrapperStyle[propertyBit]}`}>
        <div className="w-full">
          <div className="shadow-md rounded-lg  mb-4">
            <div className="flex items-center bg-20233810 rounded-md">
              <TextField
                sx={() => CustomTextFieldOutlined()}
                id="search"
                label={label ?? t("word_timezone")}
                value={valueOfSearchBox}
                onChange={handleChange}
                variant="outlined"
                InputProps={{
                  sx: CustomTextFieldOutlinedInputProps,
                }}
                color="warning"
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                fullWidth
                autoComplete="off"
              />
            </div>
            {isListVisible && (
              <div
                className="py-3 text-sm"
                style={{ maxHeight: "200px", overflowY: "auto" }}
                ref={listRef}
              >
                {filteredItems?.length > 0 ? (
                  filteredItems.map((item, index) => (
                    <div
                      key={index}
                      onMouseDown={(e) => handleMouseDown(e, item)}
                      className="flex justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2"
                    >
                      <div className="flex-grow font-medium px-2">
                        {getDisplayedProperty(item)}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-gray-700 px-3 py-1">No results</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
