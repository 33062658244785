// Library methods
import { useMemo, forwardRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVrCardboard,
  faLink,
  faUpRightAndDownLeftFromCenter,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

// MUI Components
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";

// utilities
import {
  getVisualField,
  getExamType,
  getExamAlgorithmTranslation,
  getExamTypeAbbreviation,
  getExamTypeTranslation,
} from "../../../../utils/examReportsHelper";
import CustomCheckbox from "../../../../components/UI/CustomCheckbox";
import { getLocalTime } from "../../../../utils/dateHelper";
import ExamCardShared from "../../../../components/UI/ExamCardShared";
import { LiveUpdateStatusContext } from "../../../../contexts/LiveUpdateStatusContext";

const ExamCard = forwardRef(
  (
    {
      row,
      patientId,
      isSelected,
      headCells,
      handleCheckboxClick,
      handleEditClick,
      handleViewReportClick,
      handleLinkDeviceClick,
      handleUnlinkDeviceClick,
      handleDuplicateClick,
      associatedExamDevices,
      linkLoading,
      unlinkLoading,
    },
    ref
  ) => {
    const {
      exam,
      examStatus,
      hasDevice,
      testCompletionIcon,
      checkReportDisabled,
      checkLinkDeviceDisabled,
      checkTooltipDeviceMessage,
      checkLiveUpdateDisabled,
    } = ExamCardShared(row, associatedExamDevices);
    // internationalization
    const { t } = useTranslation();

    const { handleViewLiveUpdateClick } = useContext(LiveUpdateStatusContext);

    // // check if edit button should be disabled
    const checkEditDisabled = () =>
      examStatus?.status !== t("word_not_started") || hasDevice;

    // exam related helper values
    const examType = useMemo(() => getExamType(exam), [exam]);
    const examVisualField = useMemo(() => getVisualField(exam), [exam]);
    const isEsterman = useMemo(() => examType === "Esterman", [examType]);
    const examTooltipTranslation = useMemo(
      () =>
        `${t(getExamTypeTranslation(exam))} | ${t(getExamAlgorithmTranslation(exam))}`,
      [exam, t]
    );
    const examTranslation = useMemo(
      () =>
        `${getExamTypeAbbreviation(exam)} | ${t(getExamAlgorithmTranslation(exam))}`,
      [exam, t]
    );
    const examAdditionalInfo = useMemo(() => {
      const shouldNotDisplayVisualField =
        examVisualField === "N.A" || examVisualField === "" || isEsterman;
      return shouldNotDisplayVisualField ? "" : " | " + examVisualField;
    }, [examVisualField, isEsterman]);
    const localSchedule = getLocalTime(exam?.scheduledDate ?? Date.now);

    // because of the device section being split, we needed to add a grid offset to each of the items inside the card
    return (
      <Box ref={ref}>
        <Grid
          container
          px={{ md: 0, lg: 2 }}
          py={0}
          sx={{ display: "flex", alignItems: "center", flexWrap: "no-wrap" }}
        >
          <Grid item xs={headCells[0].gridSize + 1} sm={headCells[0].gridSize}>
            {!hasDevice && (
              <Tooltip
                title={checkTooltipDeviceMessage()}
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                }}
              >
                <Card
                  disabled={checkLinkDeviceDisabled() || linkLoading}
                  elevation={0}
                  key={exam.id}
                  sx={{
                    mb: 2,
                    mr: 2,
                    borderRadius: 2,
                    backgroundColor: !isSelected ? "#202338" : "#E2772E",
                    color: "white",
                    height: { xs: 120, sm: 52 },
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                    cursor: "pointer",
                    flexWrap: "wrap",
                    // when the card is disabled, add change cursor and add a grey backgroundColor
                    ...(checkLinkDeviceDisabled() && {
                      cursor: "auto",
                      backgroundColor: "rgba(0,0,0,0.2)",
                    }),
                  }}
                  // only enable click method when the card is not disabled
                  {...(!checkLinkDeviceDisabled() &&
                    !linkLoading && {
                      onClick: () => handleLinkDeviceClick(exam),
                    })}
                >
                  <FontAwesomeIcon icon={faVrCardboard} size="2x" />
                  <FontAwesomeIcon icon={faLink} size="sm" />
                </Card>
              </Tooltip>
            )}
            {hasDevice && (
              <Tooltip title={checkTooltipDeviceMessage()}>
                <Card
                  elevation={0}
                  key={exam.id}
                  sx={{
                    mb: 2,
                    mr: 2,
                    borderRadius: 2,
                    backgroundColor: !isSelected ? "#202338" : "#E2772E",
                    color: "white",
                    height: { xs: 120, sm: 52 },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    cursor: "pointer",
                    px: 1,
                  }}
                  disabled={unlinkLoading}
                  onClick={() =>
                    handleUnlinkDeviceClick(examStatus?.device?.id)
                  }
                >
                  <FontAwesomeIcon icon={faVrCardboard} size="sm" />
                  <Typography
                    noWrap
                    sx={{
                      display: { xs: "none", sm: "block", md: "block" },
                      width: "100%",
                    }}
                  >
                    {examStatus?.device?.alias}
                  </Typography>
                </Card>
              </Tooltip>
            )}
          </Grid>
          <Grid
            item
            xs={12 - headCells[0].gridSize - 1}
            sm={12 - headCells[0].gridSize}
          >
            <Card
              elevation={0}
              key={exam.id}
              sx={{
                marginBottom: 2,
                borderRadius: 2,
                backgroundColor: !isSelected ? "#202338" : "#E2772E",
                color: "white",
                height: { xs: 120, sm: 52 },
                display: "flex",
                jutifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                py={0}
                sx={{
                  display: { xs: "grid", sm: "flex" },
                  gridTemplateColumns: { xs: "13% 70% 18%", sm: "none" },
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Grid item sm={1} sx={{ ml: { xs: 0, sm: 0 } }}>
                  <CustomCheckbox
                    checkedFill="#E2772E"
                    checked={isSelected}
                    onClick={(event) => handleCheckboxClick(event, exam.id)}
                  />
                </Grid>
                <Grid
                  item
                  sm={9}
                  display={{ sx: "block", sm: "flex" }}
                  flexDirection={{ sm: "space-between" }}
                  alignItems="center"
                >
                  <Grid item xs={12} sm={headCells[1].gridSize}>
                    <Typography
                      sx={{
                        display: { xs: "block", sm: "none" },
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "16px",
                          lg: "1rem",
                        },
                      }}
                    >
                      <b>{headCells[1].label}:</b> {examStatus?.status}
                    </Typography>
                    <Typography
                      noWrap
                      sx={{
                        display: { xs: "none", sm: "block" },
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "16px",
                          lg: "1rem",
                        },
                      }}
                    >
                      {examStatus?.status}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={headCells[2].gridSize}>
                    <Box
                      sx={{
                        display: { xs: "block", sm: "none", md: "none" },
                        // mt: 1,
                      }}
                    >
                      <Box
                        display="flex"
                        sx={{
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "16px",
                            lg: "1rem",
                          },
                        }}
                      >
                        <b style={{ marginRight: 4 }}>{headCells[2].label}:</b>
                        {testCompletionIcon}
                      </Box>
                    </Box>
                    <Box
                      noWrap
                      sx={{
                        display: { xs: "none", sm: "block", md: "block" },
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "16px",
                          lg: "1rem",
                        },
                      }}
                    >
                      {testCompletionIcon}
                    </Box>
                  </Grid>
                  <Tooltip
                    title={examTooltipTranslation + examAdditionalInfo}
                    placement="bottom-start"
                  >
                    <Grid item xs={12} sm={headCells[3].gridSize}>
                      <Typography
                        sx={{
                          display: { xs: "block", sm: "none", md: "none" },
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "16px",
                            lg: "1rem",
                          },
                        }}
                      >
                        <b>{headCells[3].label}:</b>
                        {examTranslation}
                        {examAdditionalInfo}
                      </Typography>
                      <Typography
                        noWrap
                        sx={{
                          display: { xs: "none", sm: "block", md: "block" },
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "16px",
                            lg: "1rem",
                          },
                        }}
                      >
                        {examTranslation}
                        {examAdditionalInfo}
                      </Typography>
                    </Grid>
                  </Tooltip>
                  <Grid item xs={12} sm={headCells[4].gridSize}>
                    <Typography
                      sx={{
                        display: { xs: "block", sm: "none", md: "none" },
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "16px",
                          lg: "1rem",
                        },
                      }}
                    >
                      <b>{headCells[4].label}:</b> {localSchedule.slice(0, 10)}
                    </Typography>
                    <Typography
                      noWrap
                      sx={{
                        display: { xs: "none", sm: "block", md: "block" },
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "16px",
                          lg: "1rem",
                        },
                      }}
                    >
                      {localSchedule.slice(0, 10)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={2}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: { xs: "center", sm: "end" },
                    }}
                  >
                    <Tooltip title={t("tooltip_duplicate_exam")}>
                      <span>
                        <IconButton
                          size="medium"
                          sx={{
                            color: "white",
                            padding: "4px",
                          }}
                          onClick={() => handleDuplicateClick(exam)}
                        >
                          <ContentCopyIcon fontSize="inherit" />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title={t("word_view_results")}>
                      <span>
                        <a
                          href={`/patients/${patientId}/exam/${exam.id}`}
                          style={{ textDecoration: "none" }}
                          onClick={(e) => {
                            // Prevent default behavior of <a>
                            e.preventDefault();
                            handleViewReportClick(patientId, exam.id);
                          }}
                        >
                          <IconButton
                            size="medium"
                            sx={{
                              color: "white",
                              padding: "6px 2px 1px 2px",
                              "&.Mui-disabled": {
                                color: "white",
                                opacity: "0.5",
                              },
                              display: checkReportDisabled ? "none" : "block",
                            }}
                            disabled={checkReportDisabled}
                          >
                            <PlagiarismOutlinedIcon fontSize="inherit" />
                          </IconButton>
                        </a>
                      </span>
                    </Tooltip>
                    {/* edit */}
                    <Tooltip title={t("exams_edit_modal_title")}>
                      <span>
                        <IconButton
                          sx={{
                            color: "white",
                            padding: "4px",
                            "&.Mui-disabled": {
                              color: "white",
                              opacity: "0.5",
                            },
                            display: checkEditDisabled() ? "none" : "block",
                          }}
                          disabled={checkEditDisabled()}
                          onClick={() => {
                            handleEditClick(exam);
                          }}
                        >
                          <FontAwesomeIcon size="sm" icon={faEdit} />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title={t("tooltip_live_update_panel")}>
                      <span>
                        <IconButton
                          sx={{
                            color: "white",
                            padding: "4px",
                            marginRight: "4px",
                            "&.Mui-disabled": {
                              color: "white",
                              opacity: "0.5",
                            },
                            display:
                              examStatus?.status === t("word_completed") ||
                              (examStatus?.status === t("word_terminated") &&
                                !examStatus?.timelineStage)
                                ? "none"
                                : "block",
                          }}
                          disabled={checkLiveUpdateDisabled()}
                          onClick={() => {
                            handleViewLiveUpdateClick(
                              exam,
                              examStatus?.device?.id
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            size="sm"
                            icon={faUpRightAndDownLeftFromCenter}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default ExamCard;
