import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const NewAppVersionWarningPopup = ({
  minimumCompatibleVersion = null,
  // inDashboard = true,
}) => {
  const { t } = useTranslation();
  const helpMessageParts = t("device_new_version_available").split(
    "VERSION_NUM"
  );
  return (
    <Grid
      item
      bgcolor={"#2c87e8"}
      width={"100%"}
      minHeight={{ xs: 35, sm: 30, md: 25 }}
      position={"absolute"}
      top={{ xs: 42, sm: 85 }}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      zIndex={99}
    >
      <Typography
        color={"white"}
        textAlign={"center"}
        fontSize={{ xs: "12px", sm: "14px", lg: "15px" }}
      >
        {minimumCompatibleVersion ? (
          <>
            {helpMessageParts[0]}
            {minimumCompatibleVersion}
            {helpMessageParts[1]}
          </>
        ) : (
          t("device_new_version_available_alternative")
        )}
      </Typography>
    </Grid>
  );
};

export default NewAppVersionWarningPopup;
