// Library methods
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

// MUI Components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Components
import ReportInfo from "./ReportInfo";
import PatternDevGraph from "./PatternDevGraph";
import TotalGraph from "./TotalGraph";
import NewPatternGraph from "./NewPatternGraph";
import DeviationChartLegend from "./DeviationChartLegend";
import NumberGraph from "../../../../components/UI/NumberGraph";
import GazeGraph from "../../../../components/UI/GazeGraph";

// Utilities
import {
  getChartSpots,
  calculateNeighbours,
  getPatternDevChartSpots,
  getTotalChartSpots,
  getGazeChartData,
} from "../../../../utils/examReportsHelper";
import Circle from "../../../../components/UI/Circle";
import Square from "../../../../components/UI/Square";
import CommentsList from "../../../../components/UI/CommentsList";
import { sectionCompletionState } from "../../../../hooks/useExamStatus";
import { ValidatePDFContext } from "../../../../contexts/ValidatePDFContext";
import { ReportDataContext } from "../../../../contexts/ReportDataContext";
import { ReportEachSectionContext } from "../../../../contexts/CommentContext";
import Watermark from "../../../../components/UI/Watermark";
import { ErrorMessageComponent } from "../../../../components/UI/ErrorMessage";
import html2canvas from "html2canvas";

const EyeReport = () => {
  const { forBackendPdf } = useContext(ValidatePDFContext);
  const { exam } = useContext(ReportDataContext);
  const { examReport, eyeReport, durationStartTime, duration } = useContext(
    ReportEachSectionContext
  );

  const { t, i18n } = useTranslation();

  const [chartData, setChartData] = useState({
    number: undefined,
    pattern: undefined,
    totalDev: undefined,
    patternDev: undefined,
    gaze: undefined,
  });
  const [scale, setScale] = useState(1);
  const [imageSrc, setImageSrc] = useState(null);
  // algorithm version number
  const algorithmVersion = examReport?.exam?.version?.substring(0, 3);
  const isNotRlFast = examReport?.exam?.algorithm !== "SCREEN_FAST";

  const handleShowImage = useCallback(async () => {
    const element = document.getElementById(`eye-report-${eyeReport}`);

    if (!element) {
      console.error("Element not found!");
      return;
    }

    const canvas = await html2canvas(element, {
      scale: 2,
    });
    const data = canvas.toDataURL("image/jpg");

    setImageSrc(data);
  }, [eyeReport]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleShowImage();
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [handleShowImage]);

  useEffect(() => {
    if (!examReport) return;

    const spots = getChartSpots(examReport);
    const finalPatternSpots = calculateNeighbours(spots);
    const { gazeSpots } = getGazeChartData(examReport);
    setChartData({
      number: spots,
      pattern: finalPatternSpots,
      totalDev: getTotalChartSpots(examReport),
      patternDev: getPatternDevChartSpots(examReport),
      gaze: gazeSpots,
    });
  }, [examReport]);

  useEffect(() => {
    const handleResize = () => {
      const scaleFactor =
        window.innerWidth < 800
          ? window.innerWidth / 820
          : window.innerWidth < 1300
            ? window.innerWidth / 1000
            : window.innerWidth < 1920
              ? window.innerWidth / 1750
              : 1;
      setScale(scaleFactor);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isTerminated = useMemo(() => {
    if (!exam?.visualFieldSections?.length) return;
    const eye = examReport?.exam?.eye;
    const section = exam?.visualFieldSections.find(
      (section) => section.eye === eye
    );
    return (
      section && section.completionState === sectionCompletionState.Terminated
    );
  }, [exam?.visualFieldSections, examReport?.exam?.eye]);

  const seenSpots = useMemo(() => {
    if (isNotRlFast || !examReport?.state) return [];
    return examReport?.state?.visualSpots?.length
      ? examReport.state?.visualSpots.filter((point) => point.isSeen)
      : [];
  }, [examReport?.state, isNotRlFast]);

  // should return no content when the eyeReport is empty
  if (eyeReport === "") return null;

  // build the report content
  return (
    <div className="retinalogik-section">
      <ReportInfo />
      {examReport?.postProcessing ? (
        <>
          {isNotRlFast && forBackendPdf && imageSrc ? (
            <img src={imageSrc} alt="Generated" width={800} height={600} />
          ) : (
            <>
              <div id={`eye-report-${eyeReport}`}>
                <Grid container sx={{ mt: { sx: 0, sm: 0, md: 8 } }}>
                  {isTerminated && (
                    <Watermark py={isNotRlFast ? 30 : 10} fs="5em" />
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={isNotRlFast ? 6 : 12}
                    md={isNotRlFast ? 6 : 12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      transform:
                        !forBackendPdf &&
                        (window.innerWidth >= 1300 ||
                          (window.innerWidth >= 600 &&
                            window.innerWidth <= 800))
                          ? `scale(${scale})`
                          : undefined,
                      transformOrigin:
                        !forBackendPdf && window.innerWidth >= 1300
                          ? "top left"
                          : undefined,
                    }}
                  >
                    <NumberGraph
                      pressedPoints={chartData.number || []}
                      gridType={examReport?.exam?.grid}
                      shouldDisplayTriangle={
                        examReport?.exam?.grid !== "G_10_2"
                      }
                      isNotRlFast={isNotRlFast}
                    />
                  </Grid>
                  {isNotRlFast && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      pt={{ xs: 4, sm: 0, md: 0 }}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        transform:
                          // !forBackendPdf && window.innerWidth >= 1300
                          !forBackendPdf &&
                          (window.innerWidth >= 1300 ||
                            (window.innerWidth >= 600 &&
                              window.innerWidth <= 800))
                            ? `scale(${scale})`
                            : undefined,
                        transformOrigin:
                          !forBackendPdf && window.innerWidth >= 1300
                            ? "top left"
                            : undefined,
                      }}
                    >
                      <NewPatternGraph
                        pressedPoints={chartData.pattern || []}
                        eye={eyeReport}
                        gridType={examReport?.exam?.grid}
                      />
                    </Grid>
                  )}
                </Grid>
                {/* Legend for RLFast */}
                {!isNotRlFast && (
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" mb={0.5}>
                      <Circle />
                      <Typography ml={2} variant="body1" noWrap>
                        {t("word_seen")} {seenSpots.length}/
                        {examReport?.state?.visualSpots?.length}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={0.5}>
                      <Square />
                      <Typography ml={2} variant="body1" noWrap>
                        {t("word_not_seen")}{" "}
                        {examReport?.state?.visualSpots?.length -
                          seenSpots.length}
                        /{examReport?.state?.visualSpots?.length}
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {isNotRlFast && (
                  <Grid
                    container
                    sx={{ mt: { sx: 2, sm: 2, md: 9 } }}
                    gap={5}
                    mb={4}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={4}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        transform:
                          !forBackendPdf &&
                          (window.innerWidth >= 1300 ||
                            (window.innerWidth >= 600 &&
                              window.innerWidth <= 800))
                            ? `scale(${scale})`
                            : undefined,
                      }}
                    >
                      <Typography
                        color="text.primary"
                        className="capitalize"
                        mb={2}
                      >
                        {t("total_deviation")}
                      </Typography>
                      <TotalGraph
                        pressedPoints={chartData.totalDev || []}
                        eye={eyeReport}
                        gridType={examReport?.exam?.grid}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={4}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        transform:
                          !forBackendPdf &&
                          (window.innerWidth >= 1300 ||
                            (window.innerWidth >= 600 &&
                              window.innerWidth <= 800))
                            ? `scale(${scale})`
                            : undefined,
                      }}
                    >
                      {!isNaN(examReport?.postProcessing?.totalMeanDeviation) &&
                      examReport?.postProcessing?.totalMeanDeviation >= -19 ? (
                        <>
                          <Typography
                            color="text.primary"
                            className="capitalize"
                            mb={2}
                          >
                            {t("pattern_deviation")}
                          </Typography>
                          <PatternDevGraph
                            pressedPoints={chartData.patternDev || []}
                            eye={eyeReport}
                            gridType={examReport?.exam?.grid}
                          />
                        </>
                      ) : (
                        <Typography>MD Threshold Exceeded Limit</Typography>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      md={2}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 5,
                      }}
                    >
                      <Box>
                        <Typography variant="body1" noWrap mb={0.5}>
                          MD:{" "}
                          {isTerminated
                            ? "-"
                            : (examReport?.postProcessing?.totalMeanDeviation
                                ? examReport?.postProcessing
                                    ?.totalMeanDeviation > 0
                                  ? examReport?.postProcessing
                                      ?.totalMeanDeviation % 3
                                  : examReport?.postProcessing
                                      ?.totalMeanDeviation
                                : 0
                              ).toFixed(2)}
                        </Typography>
                        <Typography variant="body1" noWrap mb={0.5}>
                          PSD:{" "}
                          {isTerminated
                            ? "-"
                            : (
                                examReport?.postProcessing
                                  ?.patternStandardDeviation ?? 0
                              ).toFixed(2)}
                        </Typography>
                      </Box>
                      <Box>
                        <DeviationChartLegend />
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </div>
            </>
          )}
          <Grid
            display="flex"
            sx={{ mt: { sx: 2, sm: 2, md: 13 } }}
            container
            item
            xs={12}
            mr={4}
            flexDirection="row"
          >
            {/* <Grid container> */}
            <Grid item xs={1} display="flex" flexDirection="column">
              <Box height="50%" display="flex" alignItems="center">
                <Typography>Fixation</Typography>
              </Box>
              <Box height="50%" display="flex" alignItems="center">
                <Typography fontSize={i18n.language === "fr" ? 12 : undefined}>
                  {t("blink")}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={11}>
              <GazeGraph data={chartData.gaze || []} />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography>{durationStartTime}</Typography>
                <Typography>{duration}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item xs={12} mt={2}>
            <CommentsList isVisualField={true} />
          </Grid>
          {!forBackendPdf && (
            <Grid
              container
              item
              display="flex"
              xs={12}
              flexDirection="row"
              justifyContent={"end"}
              mt={2}
            >
              <Grid item xs={12}>
                <Typography textAlign={"right"}>
                  {t("version")}: {algorithmVersion}
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <ErrorMessageComponent errorBit={1} />
      )}
    </div>
  );
};

export default EyeReport;
