// Library methods
import { useContext, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import {
  faVrCardboard,
  faLink,
  faLinkSlash,
  faUpRightAndDownLeftFromCenter,
  // faXmark,
} from "@fortawesome/free-solid-svg-icons";

// MUI Components
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

// Utilities
import { getBatteryIcon } from "../../../utils/deviceHelper";
import { getLocalTime } from "../../../utils/dateHelper";

// Assets
import leftControllerIconRed from "../../../assets/images/controllers/left_red.svg";
import leftControllerIconGreen from "../../../assets/images/controllers/left_black.svg";
import leftControllerIconGrey from "../../../assets/images/controllers/left_grey.svg";
import rightControllerIconGreen from "../../../assets/images/controllers/right_black.svg";
import rightControllerIconGrey from "../../../assets/images/controllers/right_grey.svg";
import rigthControllerIconRed from "../../../assets/images/controllers/right_red.svg";
import cloudSlashIcon from "../../../assets/images/controllers/cloud_grey.svg";
import { LiveUpdateStatusContext } from "../../../contexts/LiveUpdateStatusContext";
import { useExamStatus } from "../../../hooks/useExamStatus";
import { DeviceAppVersionContext } from "../../../contexts/DeviceContext";
import { IconButton } from "@mui/material";

const DevicesCard = ({
  row,
  handleLinkDeviceClick,
  handleUnlinkDeviceClick,
  isExamInProgress,
  devicesWithExams,
  linkLoading,
  unlinkLoading,
  isDashboard = false,
}) => {
  const { t } = useTranslation();

  const { getExamStatus } = useExamStatus();
  const { handleViewLiveUpdateClick } = useContext(LiveUpdateStatusContext);
  const { setNewAppVersionWarningPopup } = useContext(DeviceAppVersionContext);

  const [isLinked, setIsLinked] = useState(false);
  const device = useMemo(() => {
    // Only devicesWithExams has the latest data
    const device = devicesWithExams?.find((item) => item.id === row.id);
    if (device) {
      setIsLinked(true);
      return device;
    }
    setIsLinked(false);
    return device || row;
  }, [devicesWithExams, row]);
  // getting the exam's patient
  const linkedExamsPatient = useMemo(() => {
    // To avoid using not updated row data (associatedExam)
    if (isLinked) {
      return `${device?.associatedExam?.exam?.patient?.firstName} ${device?.associatedExam?.exam?.patient?.lastName}`;
    }
    return "";
  }, [
    device?.associatedExam?.exam?.patient?.firstName,
    device?.associatedExam?.exam?.patient?.lastName,
    isLinked,
  ]);

  const isOutdated = useMemo(() => {
    if (device?.webserviceCompatible === false) {
      return true;
    }
    return false;
  }, [device?.webserviceCompatible]);

  const handleClickOutdated = (device) => {
    console.log("outdated", device);
  };
  const convertedDeviceExpiryDate = useMemo(() => {
    const d = row?.expiryDate;
    if (d) {
      return getLocalTime(d);
    }
    return null;
  }, [row?.expiryDate]);

  // Extract version number with regular expression
  const appVersion = useMemo(() => {
    if (device?.applicationVersion) {
      const regex = /\d+(\.\d+)*/g;
      return `v${device.applicationVersion.match(regex)}`;
    }
    return null;
  }, [device?.applicationVersion]);

  const leftDisconnected = useMemo(
    () =>
      !device?.deviceStatus?.controllerBatteryL ||
      device?.deviceStatus?.controllerBatteryL === -1,
    [device?.deviceStatus?.controllerBatteryL]
  );
  const rightDisconnected = useMemo(
    () =>
      !device?.deviceStatus?.controllerBatteryR ||
      device?.deviceStatus?.controllerBatteryR === -1,
    [device?.deviceStatus?.controllerBatteryR]
  );

  const examStatus = useMemo(
    () => getExamStatus(device?.associatedExam?.exam, devicesWithExams),
    [device?.associatedExam?.exam, devicesWithExams, getExamStatus]
  );

  const shouldDisableLiveUpdate = useMemo(
    () =>
      !device?.associatedExam?.exam ||
      !device?.id ||
      examStatus?.status === t("word_not_started") ||
      examStatus?.status === t("word_completed") ||
      (examStatus?.status === t("word_terminated") &&
        !examStatus?.timelineStage) ||
      examStatus?.status === t("word_invalid") ||
      !examStatus?.device?.deviceStatus?.stage,
    [
      device?.associatedExam?.exam,
      device?.id,
      examStatus?.device?.deviceStatus?.stage,
      examStatus?.status,
      examStatus?.timelineStage,
      t,
    ]
  );

  useEffect(() => {
    if (isOutdated) setNewAppVersionWarningPopup(isOutdated);
  }, [isOutdated, setNewAppVersionWarningPopup]);

  const cardWidthStyle = useMemo(
    () =>
      isDashboard
        ? {
            xs: "170px",
            sm: "210px",
            md: "225px",
            lg: "240px",
            xl: "260px",
            xxl: "270px",
          }
        : { xs: "170px", sm: "230px", md: "250px", lg: "270px" },
    [isDashboard]
  );

  const cardHeightStyle = useMemo(
    () =>
      isDashboard
        ? {
            xs: "190px",
            sm: "150px",
            md: "155px",
          }
        : { xs: "190px", sm: "150px", md: "145px", lg: "140px" },
    [isDashboard]
  );

  return (
    <Card
      elevation={0}
      key={device?.id}
      sx={{
        position: "relative",
        width: cardWidthStyle,
        height: cardHeightStyle,
        borderRadius: 2,
        backgroundColor: "white",
        border: "2px solid #E2772E90",
        ...(isOutdated && {
          backgroundColor: "white",
        }),
        ...(isLinked && {
          backgroundColor: "rgba(0,0,0,0.1)",
          border: "2px solid rgba(0,0,0,0)",
        }),
        ...(isOutdated && {
          border: "2px solid #87201a",
        }),
        py: 1.25,
        px: 1.5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box position={"absolute"} top={2} right={2}>
        <Tooltip title={t("tooltip_live_update_panel")}>
          <span>
            <IconButton
              sx={{
                fontSize: "15px",
                color: "black",
                "&.Mui-disabled": {
                  color: "gray",
                  opacity: "0.5",
                },
              }}
              disabled={shouldDisableLiveUpdate}
              onClick={() => {
                if (shouldDisableLiveUpdate) return;
                handleViewLiveUpdateClick(
                  device.associatedExam.exam,
                  device.id
                );
              }}
            >
              <FontAwesomeIcon
                size="sm"
                icon={faUpRightAndDownLeftFromCenter}
              />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <CardContent sx={{ p: 0 }}>
        <Grid
          container
          item
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          xs={12}
          height={30}
        >
          <Grid item xs={12} sm={4} md={3.5} lg={3}>
            <Box
              sx={{
                width: { sm: "43.14px", md: "43.14px", lg: "50.35px" },
              }}
            >
              <FontAwesomeIcon
                icon={faVrCardboard}
                size="2x"
                color={
                  isLinked
                    ? "rgba(0,0,0,0.2)"
                    : isOutdated
                      ? "#87201a"
                      : "#E2772E"
                }
              />
            </Box>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={8}
            md={8.5}
            lg={9}
            display={"flex"}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Grid item xs={12}>
              <Tooltip title={device?.alias}>
                <Typography
                  noWrap
                  component="div"
                  variant="body1"
                  sx={{
                    width: { xs: "100%", sm: "80%" },
                    fontWeight: "700",
                    fontSize: { xs: "12px", sm: "14px" },
                    lineHeight: "14px",
                  }}
                >
                  {device?.alias}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                noWrap
                component="div"
                variant="body2"
                sx={{
                  fontWeight: "400",
                  width: { xs: "100%", sm: "80%" },
                  fontSize: { xs: "12px", sm: "14px" },
                  marginBottom: 0,
                }}
              >
                {appVersion}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* To check if the device is linked, which is based on the data. */}
      </CardContent>
      {/* middle section */}
      <Grid container item xs={12}>
        <Grid container item xs={8} sm={7} md={8} height={60}>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            flexDirection={"row"}
            height={25}
          >
            {device?.deviceStatus?.battery >= 0 && (
              <>
                {getBatteryIcon(device.deviceStatus.battery)}
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontWeight: 400,
                    fontSize: "13px",
                    lineHeight: "13px",
                  }}
                  pl={0.2}
                >
                  {Math.floor(device.deviceStatus.battery * 100)}%
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12} height={15}>
            <Typography
              noWrap
              variant="body2"
              sx={{ color: isLinked ? "rgba(0,0,0,0.4)" : "#E2772E" }}
              pt={0.5}
            >
              {isLinked ? linkedExamsPatient : " "}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={1} height={10}>
            {convertedDeviceExpiryDate && (
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: { xs: 9, sm: 9.5 },
                  color: "rgba(0,0,0,0.7)",
                }}
              >
                {`${t(
                  "device_card_expiry_date"
                )} : ${convertedDeviceExpiryDate}`}
              </Typography>
            )}
          </Grid>
        </Grid>
        {/* To adjust controllers' position */}
        <Grid item xs={0.5}></Grid>
        <Grid
          container
          item
          xs={3}
          sm={4}
          md={3}
          display="flex"
          alignItems={"center"}
          justifyContent={"center"}
          height={40}
        >
          <Tooltip
            title={`${t("word_left")} : ${
              leftDisconnected
                ? t("word_offline")
                : `${device?.deviceStatus?.controllerBatteryL * 100}%`
            }`}
          >
            <Grid
              container
              item
              xs={6}
              display={"flex"}
              flexDirection={"column"}
            >
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                alignItems={!leftDisconnected ? "flex-end" : "center"}
                mt={!leftDisconnected && 1}
              >
                {leftDisconnected && (
                  <img src={cloudSlashIcon} alt="offline" width={"10px"} />
                )}
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                alignItems={!leftDisconnected ? "flex-end" : "center"}
              >
                <img
                  src={
                    leftDisconnected
                      ? leftControllerIconGrey
                      : device?.deviceStatus?.controllerBatteryL <= 0.2
                        ? leftControllerIconRed
                        : leftControllerIconGreen
                  }
                  alt="leftController"
                  width="18px"
                />
              </Grid>
            </Grid>
          </Tooltip>

          <Tooltip
            title={`${t("word_right")} : ${
              rightDisconnected
                ? t("word_offline")
                : `${device?.deviceStatus?.controllerBatteryR * 100}%`
            }`}
          >
            <Grid
              container
              item
              xs={6}
              display={"flex"}
              flexDirection={"column"}
            >
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                alignItems={!rightDisconnected ? "flex-end" : "center"}
                mt={!rightDisconnected && 1}
              >
                {rightDisconnected && (
                  <img src={cloudSlashIcon} alt="offline" width={"10px"} />
                )}
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                alignItems={!rightDisconnected ? "flex-end" : "center"}
              >
                <img
                  src={
                    rightDisconnected
                      ? rightControllerIconGrey
                      : device?.deviceStatus?.controllerBatteryR <= 0.2
                        ? rigthControllerIconRed
                        : rightControllerIconGreen
                  }
                  alt="rightController"
                  width="18px"
                />
              </Grid>
            </Grid>
          </Tooltip>
        </Grid>
      </Grid>
      {/* bottom section */}
      <Grid container item xs={12} height={15}>
        <Grid
          item
          xs={12}
          sm={isDashboard ? 6 : 7}
          md={isDashboard ? 7 : 8}
          display={"flex"}
          alignItems={"flex-end"}
        >
          {device?.deviceStatus?.stage?.stageType != null ? (
            <div className="flex items-center gap-1.5">
              <div className="w-2.5 h-2.5 bg-green-500 rounded-full"></div>
              <span style={{ fontSize: "0.9em" }}>{t("word_online")}</span>
            </div>
          ) : (
            <div className="flex items-center gap-1.5">
              <div className="w-2.5 h-2.5 bg-gray-400 rounded-full"></div>
              <span style={{ fontSize: "0.9em" }}>{t("word_offline")}</span>
            </div>
          )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={isDashboard ? 6 : 5}
          md={isDashboard ? 5 : 4}
        >
          <Grid
            container
            display={"flex"}
            justifyContent={"right"}
            alignItems={"flex-end"}
          >
            {isLinked ? (
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  height: { sm: "22px", md: "28px" },
                  fontSize: { xs: "12px", sm: "14px" },
                  fontWeight: "400",
                  padding: { xs: "2px 8px", sm: "5px 10px" },
                  backgroundColor: "white",
                  color: "rgba(0,0,0,0.8)",
                  border: "2px solid #E2772E90",
                  ...(isOutdated && {
                    border: "2px solid #87201a",
                  }),
                  gap: 0.5,
                  "&:hover": {
                    backgroundColor: "rgba(0,0,0,0.1)",
                    color: "rgba(0,0,0,0.8)",
                    border: "2px solid #E2772E90",
                  },
                }}
                disabled={isExamInProgress || unlinkLoading}
                onClick={(event) => {
                  event.stopPropagation();
                  handleUnlinkDeviceClick(device?.id);
                }}
              >
                {t("unlink")}
                <FontAwesomeIcon
                  icon={faLinkSlash}
                  size="1x"
                  color="rgba(0,0,0,0.7)"
                />
              </Button>
            ) : isOutdated ? (
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  height: { sm: "22px", md: "28px" },
                  fontSize: { xs: "12px", sm: "14px" },
                  fontWeight: "400",
                  padding: { xs: "2px 8px", sm: "5px 10px" },
                  backgroundColor: "#87201a",
                  color: "white",
                  borderColor: "white",
                  gap: 1,
                  "&:hover": {
                    backgroundColor: "#87201a95",
                    color: "white",
                    borderColor: "white",
                  },
                }}
                disabled
                style={{ color: "white" }}
                onClick={() => handleClickOutdated(row)}
              >
                {t("button_outdated")}
              </Button>
            ) : (
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  height: { sm: "22px", md: "28px" },
                  fontSize: { xs: "12px", sm: "14px" },
                  fontWeight: "400",
                  padding: { xs: "2px 8px", sm: "5px 10px" },
                  backgroundColor: "#E2772E",
                  color: "white",
                  borderColor: "white",
                  gap: 1,
                  "&:hover": {
                    backgroundColor: "#E2772E95",
                    color: "white",
                    borderColor: "white",
                  },
                }}
                disabled={linkLoading || isOutdated}
                onClick={() => handleLinkDeviceClick(device?.id)}
              >
                {t("button_link")}
                <FontAwesomeIcon icon={faLink} size="1x" color="white" />
              </Button>
            )}
          </Grid>
          {/* </CardActions> */}
        </Grid>
      </Grid>
    </Card>
  );
};

export default DevicesCard;
