// Library methods
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// Styles
import {
  CustomTextFieldOutlined,
  CustomTextFieldOutlinedInputProps,
  ModalCustomConfirm,
  ModalCustomCancel,
  ModalPaperProps,
} from "../../../../styles/muiStylesHelper";
import { useCallback, useState } from "react";

const AddUserModal = ({
  rows = [],
  open,
  onClose,
  onConfirm,
  onCancel,
  userEmail,
  setUserEmail,
  clinicName,
}) => {
  // internationalization
  const { t } = useTranslation();
  const [userAlreadyExist, setUserAlreadyExist] = useState(false);

  const handleChange = useCallback(
    (e) => {
      const val = e.target.value;
      let isValid = true;
      rows.forEach((row) => {
        if (row?.user === val) isValid = false;
      });
      if (isValid) setUserAlreadyExist(false);
      else setUserAlreadyExist(true);
      setUserEmail(val);
    },
    [rows, setUserEmail]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { ...ModalPaperProps, width: 400 },
      }}
    >
      <DialogContent>
        <Box
          padding={1}
          display="flex"
          flexDirection="row"
          gap={1}
          alignItems="center"
        >
          <FontAwesomeIcon icon={faUserPlus} size="lg" />
          <Typography
            style={{ fontWeight: "bold" }}
            variant="h5"
            component="h2"
          >
            {t("add_user")}
          </Typography>
        </Box>
        <Box padding={1}>
          {/* clinic name field*/}
          <TextField
            id="outlined-basic"
            value={userEmail}
            onChange={handleChange}
            style={{ marginTop: "1.25rem" }}
            label={t("users_table_email")}
            variant="outlined"
            sx={() => CustomTextFieldOutlined({ mt: "1.25rem" })}
            InputProps={{
              sx: CustomTextFieldOutlinedInputProps,
            }}
            error={userAlreadyExist}
            helperText={userAlreadyExist && t("clinic_same_email_error")}
            color="warning"
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ padding: 3, display: "flex", justifyContent: "center" }}
      >
        <Button
          variant="text"
          onClick={onCancel}
          sx={ModalCustomCancel}
          style={{ color: "black" }}
        >
          {t("button_cancel")}
        </Button>
        <Button
          variant="outlined"
          sx={ModalCustomConfirm}
          color="primary"
          disabled={!userEmail || userAlreadyExist}
          onClick={onConfirm}
        >
          {t("button_add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserModal;
