// Library methods
import { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

// MUI Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// Components
import DevicesManagementList from "../../containers/DevicesManagement/DevicesManagementList";

// Utilities
import { getDevices } from "../../services/Device";
import { getClinics } from "../../services/Clinic";
import getUserFromToken from "../../utils/tokenHelper";

// Styles
import { PageContainerWrapper } from "../../styles/muiStylesHelper";
import { useAdminSignalR } from "../../hooks/useAdminSignalR";

const DevicesManagement = () => {
  // routing
  const navigate = useNavigate();

  // auth
  const { getAccessTokenSilently } = useAuth0();

  // devices and clinics
  const [devices, setDevices] = useState([]);
  const [clinics, setClinics] = useState([]);

  // indicates if the data has been fetched, or if it is still being fetched
  const [isLoading, setIsLoading] = useState(true);

  const handleDeviceListUpdated = useCallback(async (data) => {
    // get all devices
    const devicesData = JSON.parse(data);

    setDevices(devicesData?.data || []);
  }, []);

  // auth signalR
  useAdminSignalR(handleDeviceListUpdated);

  const fetchClinics = useCallback(async () => {
    try {
      // get the devices list
      const token = await getAccessTokenSilently();
      const clinicsList = await getClinics(token);
      setClinics(clinicsList);
    } catch (e) {
      console.log(e);
    }
  }, [getAccessTokenSilently]);

  const fetchDevices = useCallback(async () => {
    try {
      // get the devices list
      const token = await getAccessTokenSilently();
      const devicesList = await getDevices(token);
      setDevices(devicesList);
    } catch (e) {
      //console.log(e);
    }
  }, [getAccessTokenSilently]);

  // method that does the initial data fetching, showing the loading indicator
  const initialFetch = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const { isAdmin } = getUserFromToken(token);

    // if user is an admin
    if (isAdmin) {
      setIsLoading(true);

      try {
        const fetchClinicsList = fetchClinics();
        const fetchDevicesList = fetchDevices();
        await Promise.all([fetchClinicsList, fetchDevicesList]);
      } catch (e) {
        //console.log(e);
      } finally {
        setIsLoading(false);
      }
    } else navigate("/");
  }, [fetchClinics, fetchDevices, getAccessTokenSilently, navigate]);

  // data fetching
  useEffect(() => {
    initialFetch();
  }, [fetchClinics, fetchDevices, initialFetch]);

  // content to be displayed after data fetching is finished
  return (
    <Box
      sx={() => PageContainerWrapper()}
      px={{ xs: 2, sm: 4, md: 6, lg: 6 }}
      mb={{ xs: 2 }}
    >
      <Box display="flex">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DevicesManagementList
              isLoading={isLoading}
              rows={devices}
              clinicsOptions={clinics}
              refetchDevices={fetchDevices}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DevicesManagement;
