// Library Methods
import { useTranslation } from "react-i18next";

// MUI Components
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useContext, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

// Utilities
import {
  ModalCustomOutlined,
  ModalCustomSwitch,
} from "../../../../styles/muiStylesHelper";
import {
  AlgorithmContext,
  ExamTypeContext,
  EyeContext,
  PositionContext,
} from "../../../../contexts/ExamPropertyContext";
import { availableLanguages } from "../../../../i18n/languages";

const CustomButton = ({
  value,
  currentValue,
  setFunction,
  label,
  fieldType,
  fieldTypeValue,
}) => {
  const isCurrentFieldType = fieldType === fieldTypeValue;
  const isSelected = currentValue === value;

  const variant = isCurrentFieldType && isSelected ? "text" : "outlined";
  const modalType = isSelected
    ? "fill"
    : isCurrentFieldType
      ? "outlined"
      : "none";

  return (
    <Button
      variant={variant}
      sx={{
        ...ModalCustomOutlined(modalType),
        marginTop: { xs: "5px", sm: 0 },
      }}
      onClick={() => setFunction(value)}
      disabled={!isCurrentFieldType}
    >
      {label}
    </Button>
  );
};

const SectionHeader = ({ title, tooltip, fieldType, fieldTypeValue, t }) => {
  const isCurrentFieldType = fieldType === fieldTypeValue;

  return (
    <Grid
      item
      display="flex"
      justifyContent={"space-between"}
      alignItems={"center"}
      marginBottom={".5rem"}
    >
      <Typography
        color={isCurrentFieldType ? "#FFF" : "#ACA8A8"}
        fontSize={"16px"}
        fontWeight={"500"}
        marginRight={".5rem"}
      >
        {t(title)}
      </Typography>
      <Tooltip title={t(tooltip)}>
        <FontAwesomeIcon
          icon={faInfoCircle}
          style={{
            color: isCurrentFieldType ? "#FFF" : "#ACA8A8",
          }}
        />
      </Tooltip>
    </Grid>
  );
};

const ExamSliderCard = ({
  label,
  examType,
  examFieldType,
  setExamFieldType,
  stimulusSizeType,
  fixationType = { fixationColorType: null, fixationPointShapeType: null },
  examAlgorithm,
  visualGrid,
  requiresTutorial,
  leftCheckBox,
  rightCheckBox,
  sectionOrder = [0, 1],
  setExamAlgorithm,
  setVisualGrid,
  setRequiresTutorial,
  setLeftCheckBox,
  setRightCheckBox,
  setStimulusSizeType,
  setFixationType,
  autoPause,
  setAutoPause,
  encouragement,
  setEncouragement,
  calibration,
  setCalibration,
  unlimitedTime,
  setUnlimitedTime,
  position,
  setPosition,
  setSectionOrder,
  binocular,
  setBinocular,
  fixationOptions = {
    sphere: "Sphere",
    diamond: "Diamond",
  },
  visualFieldExamDefaultValues = {
    examAlgorithm: "FAST_THRESHOLD",
    leftCheckBox: true,
    rightCheckBox: true,
    requiresTutorial: true,
    autoPause: true,
    encouragement: true,
    visualGrid: "G_24_2",
    stimulusSizeType: "III",
    fixationType: { fixationColorType: null, fixationPointShapeType: "Sphere" },
    calibration: true,
    rightFirst: true,
  },
}) => {
  // internationalization
  const { t, i18n } = useTranslation();

  const {
    typeVisualField,
    typeColorVision,
    typeContrastSensitivity,
    TYPE_VISUAL_ACUITY,
    path,
  } = useContext(ExamTypeContext);
  const { eyeLeft, eyeRight, eyeBoth } = useContext(EyeContext);
  const {
    esterman,
    full120,
    landoltC,
    snellen,
    tumbling,
    TUMBLING_E,
    LANDOLT_C,
    SNELLEN,
  } = useContext(AlgorithmContext);
  const { near, far } = useContext(PositionContext);

  const isCurrentFieldType = examFieldType === examType?.value;

  const isEn = useMemo(() => {
    const en = availableLanguages[0];
    const language = i18n.language;
    return language.includes(en);
  }, [i18n.language]);

  const handleChange = useCallback(
    (e, value) => {
      if (!e.target.checked) {
        setExamFieldType("");
      } else {
        setExamFieldType(value);
        if (value === path[typeVisualField]) {
          setExamAlgorithm(visualFieldExamDefaultValues.examAlgorithm);
          setLeftCheckBox(visualFieldExamDefaultValues.leftCheckBox);
          setRightCheckBox(visualFieldExamDefaultValues.rightCheckBox);
          setRequiresTutorial(visualFieldExamDefaultValues.requiresTutorial);
          setAutoPause(visualFieldExamDefaultValues.autoPause);
          setEncouragement(visualFieldExamDefaultValues.encouragement);
          setVisualGrid(visualFieldExamDefaultValues.visualGrid);
          setStimulusSizeType(visualFieldExamDefaultValues.stimulusSizeType);
          setCalibration(visualFieldExamDefaultValues.calibration);
          setFixationType(visualFieldExamDefaultValues.fixationType);
          setSectionOrder([
            visualFieldExamDefaultValues.rightFirst ? 0 : 1,
            visualFieldExamDefaultValues.rightFirst ? 1 : 0,
          ]);
        } else if (value === path[full120]) {
          setExamAlgorithm(full120);
          setLeftCheckBox(true);
          setRightCheckBox(true);
          setRequiresTutorial(true);
          setAutoPause(true);
          setEncouragement(true);
          setVisualGrid("G_120");
          setStimulusSizeType("III");
          setCalibration(true);
          setFixationType(visualFieldExamDefaultValues.fixationType);
        } else if (value === path[typeContrastSensitivity]) {
          setExamAlgorithm("PELLI_ROBSON");
          setLeftCheckBox(true);
          setRightCheckBox(true);
          setRequiresTutorial(true);
          setBinocular(true);
        } else if (value === path[typeColorVision]) {
          setExamAlgorithm("D_15");
          setLeftCheckBox(true);
          setRightCheckBox(true);
          setRequiresTutorial(true);
          setUnlimitedTime(false);
          setBinocular(true);
        } else if (value === path[esterman]) {
          setExamAlgorithm("ESTERMAN_FAST");
          setLeftCheckBox(true);
          setRightCheckBox(true);
          setVisualGrid("G_120");
          setAutoPause(true);
          setEncouragement(true);
          setRequiresTutorial(true);
          setCalibration(true);
          setFixationType(visualFieldExamDefaultValues.fixationType);
          setBinocular(true);
        } else if (value === path[TYPE_VISUAL_ACUITY]) {
          setExamAlgorithm(TUMBLING_E);
          setLeftCheckBox(true);
          setRightCheckBox(true);
          setPosition(near);
          setRequiresTutorial(true);
          setBinocular(true);
        }
      }
    },
    [
      setExamFieldType,
      path,
      typeVisualField,
      full120,
      typeContrastSensitivity,
      typeColorVision,
      esterman,
      TYPE_VISUAL_ACUITY,
      setExamAlgorithm,
      visualFieldExamDefaultValues.examAlgorithm,
      visualFieldExamDefaultValues.leftCheckBox,
      visualFieldExamDefaultValues.rightCheckBox,
      visualFieldExamDefaultValues.requiresTutorial,
      visualFieldExamDefaultValues.autoPause,
      visualFieldExamDefaultValues.encouragement,
      visualFieldExamDefaultValues.visualGrid,
      visualFieldExamDefaultValues.stimulusSizeType,
      visualFieldExamDefaultValues.calibration,
      visualFieldExamDefaultValues.fixationType,
      visualFieldExamDefaultValues.rightFirst,
      setLeftCheckBox,
      setRightCheckBox,
      setRequiresTutorial,
      setAutoPause,
      setEncouragement,
      setVisualGrid,
      setStimulusSizeType,
      setCalibration,
      setFixationType,
      setSectionOrder,
      setBinocular,
      setUnlimitedTime,
      TUMBLING_E,
      setPosition,
      near,
    ]
  );

  const handleEyeChange = useCallback(
    (value) => {
      if (value === eyeBoth) {
        setLeftCheckBox(true);
        setRightCheckBox(true);
      } else if (value === eyeLeft) {
        setLeftCheckBox(true);
        setRightCheckBox(false);
      } else if (value === eyeRight) {
        setLeftCheckBox(false);
        setRightCheckBox(true);
      } else {
        setLeftCheckBox(false);
        setRightCheckBox(false);
      }
    },
    [eyeBoth, eyeLeft, eyeRight, setLeftCheckBox, setRightCheckBox]
  );

  const refresh = (e, value) => {
    if (!e.target.checked || examFieldType !== value) {
      setRightCheckBox(false);
      setLeftCheckBox(false);
      setRequiresTutorial(false);
      setVisualGrid("");
      setExamAlgorithm("");
    }
  };

  const EyeOption = ({ eye, checkBox1, checkBox2, handleEyeChange }) => {
    const checkedCondition =
      eye === eyeBoth
        ? checkBox1 && checkBox2 && isCurrentFieldType
        : eye === eyeLeft
          ? checkBox1 && !checkBox2 && isCurrentFieldType
          : !checkBox1 && checkBox2 && isCurrentFieldType;

    return (
      <Grid
        item
        textAlign={"center"}
        marginBottom={
          eye !== eyeBoth && examType?.value !== path[full120]
            ? ".5rem"
            : undefined
        }
      >
        <Radio
          checked={checkedCondition}
          onChange={() => handleEyeChange(eye)}
          value="a"
          name="radio-buttons"
          inputProps={{ "aria-label": eye }}
          sx={{
            "&.MuiButtonBase-root.MuiRadio-root": {
              color: "#E2772E",
            },
          }}
          disabled={!isCurrentFieldType}
        />
        <Typography
          fontSize={"10px"}
          fontWeight={"600"}
          color={isCurrentFieldType ? "#FFF" : "#ACA8A8"}
        >
          {t(`word_${eye.toLowerCase()}`)}
        </Typography>
      </Grid>
    );
  };

  const ToggleGrid = ({ title, tooltip, checked, onChange, mb = ".5rem" }) => (
    <Grid
      item
      display="flex"
      justifyContent={"space-between"}
      alignItems={"center"}
      marginBottom={mb}
    >
      <SectionHeader
        title={title}
        tooltip={tooltip}
        fieldType={examFieldType}
        fieldTypeValue={examType?.value}
        t={t}
      />
      <Grid item display="flex" justifyContent={"space-between"}>
        <FormControlLabel
          sx={{
            marginBottom: examType?.value === path[esterman] ? ".5rem" : 0,
          }}
          control={
            <Switch
              checked={checked}
              onChange={onChange}
              sx={{ ...ModalCustomSwitch() }}
              disabled={examFieldType !== examType?.value}
            />
          }
          label=""
        />
      </Grid>
    </Grid>
  );

  const handleChangeSectionOrderToggle = useCallback(() => {
    setSectionOrder((prev) => {
      const ensuredPrev = prev ?? [];
      return ensuredPrev.map((p) => 1 ^ p);
    });
  }, [setSectionOrder]);

  const whichEyeComponent = useMemo(
    () => (
      <Grid
        container
        item
        display="flex"
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        pr={
          examFieldType === examType?.value && leftCheckBox && rightCheckBox
            ? 2
            : 0
        }
      >
        <SectionHeader
          title="word_which_eye"
          tooltip="eye_test_tooltip"
          fieldType={examFieldType}
          fieldTypeValue={examType?.value}
          t={t}
        />
        <Grid item display="flex" justifyContent={"space-between"}>
          <EyeOption
            eye={eyeBoth}
            checkBox1={leftCheckBox}
            checkBox2={rightCheckBox}
            handleEyeChange={handleEyeChange}
            t={t}
          />
          <EyeOption
            eye={eyeLeft}
            checkBox1={leftCheckBox}
            checkBox2={rightCheckBox}
            handleEyeChange={handleEyeChange}
            t={t}
          />
          <EyeOption
            eye={eyeRight}
            checkBox1={leftCheckBox}
            checkBox2={rightCheckBox}
            handleEyeChange={handleEyeChange}
            t={t}
          />
        </Grid>
        {examFieldType === examType?.value && leftCheckBox && rightCheckBox && (
          <Grid
            item
            display="flex"
            flexDirection={"column"}
            justifyContent={"space-between"}
            alignItems={"center"}
            marginBottom={".5rem"}
            mr={i18n.language === "en" ? 1 : 0}
          >
            <Grid
              item
              display="flex"
              justifyContent={"space-between"}
              width={30}
              mb={0.5}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={sectionOrder?.[0] === 0}
                    onChange={handleChangeSectionOrderToggle}
                    sx={{ ...ModalCustomSwitch() }}
                    disabled={examFieldType !== examType?.value}
                  />
                }
                label=""
              />
            </Grid>
            <Grid
              fontSize={"10px"}
              fontWeight={"600"}
              color={isCurrentFieldType ? "#FFF" : "#ACA8A8"}
            >
              {t("exams_modal_right_first")}
            </Grid>
          </Grid>
        )}
      </Grid>
    ),
    [
      examFieldType,
      examType?.value,
      eyeBoth,
      eyeLeft,
      eyeRight,
      handleChangeSectionOrderToggle,
      handleEyeChange,
      i18n.language,
      isCurrentFieldType,
      leftCheckBox,
      rightCheckBox,
      sectionOrder,
      t,
    ]
  );

  return (
    <Grid
      container
      display="flex"
      width="350px"
      height={{ xs: "600px", sm: "570px" }}
      flexDirection="column"
      justifyContent={!(examType?.value === path[full120]) && "space-between"}
      my="1rem"
      padding="1rem"
      borderRadius={"10px"}
      boxShadow="-20px 0px 25px rgba(0, 0, 0, 0.05)"
      border-radius="16px"
      border="1px solid #CFCFCF"
      backgroundColor={isCurrentFieldType ? "#282C3C" : "#FFF"}
    >
      <Grid>
        <FormControlLabel
          color={isCurrentFieldType ? "#FFF" : "#ACA8A8"}
          sx={{
            marginBottom:
              examType?.value === path[typeVisualField]
                ? 0.5
                : examType?.value === path[full120]
                  ? 0
                  : "1rem",
            color: isCurrentFieldType ? "#FFF" : "#ACA8A8",

            "& .MuiFormControlLabel-label": { fontWeight: "600" },
          }}
          control={
            <Switch
              checked={examFieldType === examType?.value ?? false}
              onChange={(e) => {
                refresh(e, examType?.value);
                handleChange(e, examType?.value);
              }}
              sx={{
                ...ModalCustomSwitch(),
              }}
            />
          }
          label={label}
        />

        {examType?.value === path[typeVisualField] && (
          <>
            {/* method */}
            <Grid
              item
              display="flex"
              justifyContent={"space-between"}
              alignItems={"center"}
              marginBottom={".5rem"}
            >
              <SectionHeader
                title="word_method"
                tooltip="method_tooltip"
                fieldType={examFieldType}
                fieldTypeValue={examType?.value}
                t={t}
              />
              <Grid
                container
                item
                display="flex"
                flexDirection="column"
                marginBottom={".5rem"}
              >
                <Grid
                  display="flex"
                  flexDirection="row"
                  justifyContent={"right"}
                  marginBottom={0.5}
                >
                  <CustomButton
                    value="SCREEN_FAST"
                    currentValue={examAlgorithm}
                    setFunction={setExamAlgorithm}
                    label={t("word_screen_fast")}
                    fieldType={examFieldType}
                    fieldTypeValue={examType?.value}
                  />
                  <CustomButton
                    value="FAST_THRESHOLD"
                    currentValue={examAlgorithm}
                    setFunction={setExamAlgorithm}
                    label={t("word_fast")}
                    fieldType={examFieldType}
                    fieldTypeValue={examType?.value}
                  />
                </Grid>
                <Grid
                  display="flex"
                  flexDirection="row"
                  justifyContent={"right"}
                >
                  <CustomButton
                    value="STANDARD_THRESHOLD"
                    currentValue={examAlgorithm}
                    setFunction={setExamAlgorithm}
                    label={t("word_standard")}
                    fieldType={examFieldType}
                    fieldTypeValue={examType?.value}
                  />
                  <CustomButton
                    value="FULL_THRESHOLD"
                    currentValue={examAlgorithm}
                    setFunction={setExamAlgorithm}
                    label={t("word_full")}
                    fieldType={examFieldType}
                    fieldTypeValue={examType?.value}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* grid */}
            <Grid
              item
              display="flex"
              justifyContent={"space-between"}
              alignItems={"center"}
              marginBottom={".5rem"}
            >
              <SectionHeader
                title="word_grid"
                tooltip="grid_size_tooltip"
                fieldType={examFieldType}
                fieldTypeValue={examType?.value}
                t={t}
              />
              <Grid
                container
                item
                display="flex"
                flexDirection="column"
                marginBottom={".5rem"}
              >
                <Grid
                  display="flex"
                  flexDirection="row"
                  justifyContent={"right"}
                  marginBottom={0.5}
                >
                  <CustomButton
                    value="G_24_2"
                    currentValue={visualGrid}
                    setFunction={setVisualGrid}
                    label="24-2"
                    fieldType={examFieldType}
                    fieldTypeValue={examType?.value}
                  />
                  <CustomButton
                    value="G_24_2_C"
                    currentValue={visualGrid}
                    setFunction={setVisualGrid}
                    label="24-2C"
                    fieldType={examFieldType}
                    fieldTypeValue={examType?.value}
                  />
                </Grid>
                <Grid
                  display="flex"
                  flexDirection="row"
                  justifyContent={"right"}
                >
                  <CustomButton
                    value="G_30_2"
                    currentValue={visualGrid}
                    setFunction={setVisualGrid}
                    label="30-2"
                    fieldType={examFieldType}
                    fieldTypeValue={examType?.value}
                  />
                  <CustomButton
                    value="G_10_2"
                    currentValue={visualGrid}
                    setFunction={setVisualGrid}
                    label="10-2"
                    fieldType={examFieldType}
                    fieldTypeValue={examType?.value}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        {examType?.value === path[typeContrastSensitivity] && (
          <Grid
            item
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            marginBottom={".5rem"}
          >
            <SectionHeader
              title="exams_table_type"
              tooltip="more_available_tooltip"
              fieldType={examFieldType}
              fieldTypeValue={examType?.value}
              t={t}
            />

            <Grid
              item
              display="flex"
              justifyContent={"space-between"}
              marginBottom={".5rem"}
            >
              <CustomButton
                value="PELLI_ROBSON"
                currentValue={examAlgorithm}
                setFunction={setExamAlgorithm}
                label="Pelli Robson"
                fieldType={examFieldType}
                fieldTypeValue={examType?.value}
              />
              <CustomButton
                value="TUMBLING_E"
                currentValue={examAlgorithm}
                setFunction={setExamAlgorithm}
                label="Tumbling E"
                fieldType={examFieldType}
                fieldTypeValue={examType?.value}
              />
            </Grid>
          </Grid>
        )}

        {examType?.value === path[TYPE_VISUAL_ACUITY] && (
          <Grid
            item
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            marginBottom={".5rem"}
          >
            <SectionHeader
              title="exams_table_type"
              tooltip="more_available_tooltip"
              fieldType={examFieldType}
              fieldTypeValue={examType?.value}
              t={t}
            />

            <Grid
              item
              display="flex"
              justifyContent={"space-between"}
              marginBottom={".5rem"}
            >
              {/* <CustomButton
                value={}
                currentValue={examAlgorithm}
                setFunction={setExamAlgorithm}
                label={}
                fieldType={examFieldType}
                fieldTypeValue={examType?.value}
              /> */}
              <CustomButton
                value={TUMBLING_E}
                currentValue={examAlgorithm}
                setFunction={setExamAlgorithm}
                label={tumbling}
                fieldType={examFieldType}
                fieldTypeValue={examType?.value}
              />
              <CustomButton
                value={LANDOLT_C}
                currentValue={examAlgorithm}
                setFunction={setExamAlgorithm}
                label={landoltC}
                fieldType={examFieldType}
                fieldTypeValue={examType?.value}
              />
              <CustomButton
                value={SNELLEN}
                currentValue={examAlgorithm}
                setFunction={setExamAlgorithm}
                label={snellen}
                fieldType={examFieldType}
                fieldTypeValue={examType?.value}
              />
            </Grid>
          </Grid>
        )}

        {/* type */}
        {examType?.value !== path[typeVisualField] &&
          examType?.value !== path[typeContrastSensitivity] &&
          examType?.value !== path[TYPE_VISUAL_ACUITY] && (
            <Grid
              item
              display="flex"
              justifyContent={"space-between"}
              alignItems={"center"}
              marginBottom={examType?.value === path[full120] ? 0 : ".5rem"}
            >
              <SectionHeader
                title="exams_table_type"
                tooltip="more_available_tooltip"
                fieldType={examFieldType}
                fieldTypeValue={examType?.value}
                t={t}
              />
              <Grid
                item
                display="flex"
                justifyContent={"space-between"}
                marginBottom={".5rem"}
              >
                <Button
                  variant={
                    examFieldType === examType?.type ? "outlined" : "text"
                  }
                  sx={{
                    ...ModalCustomOutlined(
                      examAlgorithm === examType?.type
                        ? "fill"
                        : examFieldType === examType?.type
                          ? "outlined"
                          : "none"
                    ),
                  }}
                  onClick={() => setExamAlgorithm(examType?.type)}
                  disabled={examFieldType !== examType?.value}
                >
                  {examType?.displayType}
                </Button>
              </Grid>
            </Grid>
          )}

        {/* Position for Visual Acuity */}
        {examType?.value === path[TYPE_VISUAL_ACUITY] && (
          <Grid
            item
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            marginBottom={".5rem"}
          >
            <SectionHeader
              title="exams_table_position"
              tooltip="more_available_tooltip"
              fieldType={examFieldType}
              fieldTypeValue={examType?.value}
              t={t}
            />

            <Grid
              item
              display="flex"
              justifyContent={"space-between"}
              marginBottom={".5rem"}
            >
              <CustomButton
                value={near}
                currentValue={position}
                setFunction={setPosition}
                label={t("word_near")}
                fieldType={examFieldType}
                fieldTypeValue={examType?.value}
              />
              <CustomButton
                value={far}
                currentValue={position}
                setFunction={setPosition}
                label={t("word_far")}
                fieldType={examFieldType}
                fieldTypeValue={examType?.value}
              />
            </Grid>
          </Grid>
        )}

        {/* Grid for full120 */}
        {examType?.value === path[full120] && (
          <Grid
            item
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            marginBottom={0}
          >
            <SectionHeader
              title="word_grid"
              tooltip="grid_size_tooltip"
              fieldType={examFieldType}
              fieldTypeValue={examType?.value}
              t={t}
            />
            <Grid
              item
              display="flex"
              justifyContent={"center"}
              marginBottom={".5rem"}
              alignItems={"center"}
              flexWrap={"wrap"}
            >
              <CustomButton
                value="G_120"
                currentValue={visualGrid}
                setFunction={setVisualGrid}
                label="120"
                fieldType={examFieldType}
                fieldTypeValue={examType?.value}
              />
            </Grid>
          </Grid>
        )}

        {/* stimulus */}
        {(examType?.value === path[typeVisualField] ||
          examType?.value === path[full120]) && (
          <Grid
            item
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            marginBottom={examType?.value === path[full120] ? 0 : ".5rem"}
          >
            <SectionHeader
              title="report_stimulus_size"
              tooltip="stimulus_size_tooltip"
              fieldType={examFieldType}
              fieldTypeValue={examType?.value}
              t={t}
            />

            <Grid
              item
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
              marginBottom={".5rem"}
              flexWrap={"wrap"}
            >
              <CustomButton
                value="III"
                currentValue={stimulusSizeType}
                setFunction={setStimulusSizeType}
                label="III"
                fieldType={examFieldType}
                fieldTypeValue={examType?.value}
              />
              <CustomButton
                value="IV"
                currentValue={stimulusSizeType}
                setFunction={setStimulusSizeType}
                label="IV"
                fieldType={examFieldType}
                fieldTypeValue={examType?.value}
              />
              <CustomButton
                value="V"
                currentValue={stimulusSizeType}
                setFunction={setStimulusSizeType}
                label="V"
                fieldType={examFieldType}
                fieldTypeValue={examType?.value}
              />
            </Grid>
          </Grid>
        )}

        {/* Fixation */}
        {(examType?.value === path[esterman] ||
          examType?.value === path[typeVisualField] ||
          examType?.value === path[full120]) && (
          <Grid
            item
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            marginBottom={examType?.value === path[full120] ? 0 : ".5rem"}
          >
            <SectionHeader
              title="fixation"
              tooltip="fixation_tooltip"
              fieldType={examFieldType}
              fieldTypeValue={examType?.value}
              t={t}
            />

            <Grid
              item
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
              marginBottom={".5rem"}
              flexWrap={"wrap"}
            >
              <CustomButton
                value={fixationOptions.sphere}
                currentValue={fixationType.fixationPointShapeType}
                setFunction={(value) =>
                  setFixationType((prev) => {
                    return {
                      fixationColorType: prev?.fixationColorType,
                      fixationPointShapeType: value,
                    };
                  })
                }
                label={t("sphere")}
                fieldType={examFieldType}
                fieldTypeValue={examType?.value}
              />
              <CustomButton
                value={fixationOptions.diamond}
                currentValue={fixationType.fixationPointShapeType}
                setFunction={(value) =>
                  setFixationType((prev) => {
                    return {
                      fixationColorType: prev?.fixationColorType,
                      fixationPointShapeType: value,
                    };
                  })
                }
                label={t("diamond")}
                fieldType={examFieldType}
                fieldTypeValue={examType?.value}
              />
            </Grid>
          </Grid>
        )}

        {/* eye */}
        {examType?.value === path[typeVisualField] ||
        examType?.value === path[full120] ? (
          whichEyeComponent
        ) : (
          <>
            <Grid
              item
              display="flex"
              justifyContent={"space-between"}
              alignItems={"center"}
              marginBottom={".5rem"}
            >
              <SectionHeader
                title="word_eye"
                tooltip="which_eye_test_tooltip"
                fieldType={examFieldType}
                fieldTypeValue={examType?.value}
                t={t}
              />
              <Grid
                item
                display="flex"
                justifyContent={"center"}
                alignItems={"center"}
                // marginBottom={".5rem"}
                flexWrap={"wrap"}
              >
                {examType?.value !== path[esterman] && (
                  <CustomButton
                    value={false}
                    currentValue={binocular}
                    setFunction={setBinocular}
                    label={t("word_monocular")}
                    fieldType={examFieldType}
                    fieldTypeValue={examType?.value}
                  />
                )}
                <CustomButton
                  value={true}
                  currentValue={binocular}
                  setFunction={setBinocular}
                  label={t("word_binocular")}
                  fieldType={examFieldType}
                  fieldTypeValue={examType?.value}
                />
              </Grid>
            </Grid>
            {!binocular &&
              examFieldType === examType?.value &&
              whichEyeComponent}
          </>
        )}

        {(examType?.value === path[esterman] ||
          examType?.value === path[typeVisualField] ||
          examType?.value === path[full120]) && (
          <>
            {/* auto-pause */}
            <ToggleGrid
              title="auto_pause"
              tooltip="auto_pause_tooltip"
              checked={
                (examFieldType === examType?.value && autoPause) ?? false
              }
              onChange={(e) => setAutoPause(e.target.checked)}
              mb={examType?.value === path[esterman] ? ".3rem" : 0}
            />

            {/* encouragement */}
            <ToggleGrid
              title="encouragement"
              tooltip="encouragement_tooltip"
              checked={
                (examFieldType === examType?.value && encouragement) ?? false
              }
              onChange={(e) => setEncouragement(e.target.checked)}
              mb={examType?.value === path[esterman] ? ".3rem" : 0}
            />

            {/* Calibration */}
            <ToggleGrid
              title="calibration"
              tooltip="calibration_tooltip"
              checked={
                (examFieldType === examType?.value && calibration) ?? false
              }
              onChange={(e) => setCalibration(e.target.checked)}
              mb={examType?.value === path[esterman] ? ".3rem" : 0}
            />
          </>
        )}

        {/* tutorial */}
        <ToggleGrid
          title="exams_table_expand_tutorial"
          tooltip="tutorial_tooltip"
          checked={
            (examFieldType === examType?.value && requiresTutorial) ?? false
          }
          onChange={(e) => setRequiresTutorial(e.target.checked)}
        />

        {/* unlimited time */}
        {examType?.value === path[typeColorVision] && (
          <ToggleGrid
            title={t("unlimited_time")}
            tooltip="unlimited_time_tooltip"
            checked={
              (examFieldType === examType?.value && unlimitedTime) ?? false
            }
            onChange={(e) => setUnlimitedTime(e.target.checked)}
            mb={examType?.value === path[typeColorVision] ? ".3rem" : 0}
          />
        )}
      </Grid>

      {examType?.value !== path[typeVisualField] && (
        <Grid
          item
          textAlign={"center"}
          fontStyle={"italic"}
          marginBottom={examType?.value === path[full120] ? 0 : ".5rem"}
          float="bottom"
        >
          <Typography
            color={examFieldType === examType?.value ? "#DADADA" : "#ACA8A8"}
            fontSize={
              examType?.value === path[full120] && !isEn ? "12px" : "14px"
            }
            fontWeight={"300"}
            marginBottom={".5rem"}
          >
            {t("new_test")}
          </Typography>
          <Typography
            color={examFieldType === examType?.value ? "#DADADA" : "#ACA8A8"}
            fontSize={
              examType?.value === path[full120] && !isEn ? "12px" : "14px"
            }
            fontWeight={"300"}
          >
            {t("try_it_out")}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ExamSliderCard;
