// Library methods
import { useCallback, useMemo, useState } from "react";

// MUI Components
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Apartment from "@mui/icons-material/Apartment";

// Utilities
import useClinic from "../../../hooks/useClinic";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { getClinics } from "../../../services/Clinic";
import { allLanguageMap, languageMap } from "../../../i18n/languages";
import { getComparator } from "../../../utils/tableHelper";

const ClinicSwitcher = () => {
  // clinic context
  const {
    clinicName,
    setClinicId,
    setClinicName,
    allClinicInfo,
    setClinicLang,
    setPatientDefaultLang,
    setClinicSettings,
  } = useClinic();

  const { i18n } = useTranslation();

  // auth0
  const { getAccessTokenSilently } = useAuth0();

  // states init
  const [menu, setMenu] = useState(null);

  // routing
  const navigate = useNavigate();

  // clinic menu handling
  const clinicMenuClick = (event) => setMenu(event.currentTarget);
  const clinicMenuClose = () => setMenu(null);

  // handles the clinic selection
  const handleClinicChange = (clinic) => {
    setClinicId(clinic.id);
    setClinicName(clinic.name);
    navigate("/");
    clinicMenuClose();
    changeClinicLanguage(clinic.id);
  };

  const changeClinicLanguage = useCallback(
    async (id) => {
      try {
        const token = await getAccessTokenSilently();
        const { settings } = await getClinics(token, id);
        const getObjectKey = (obj, value) => {
          return Object.keys(obj).find((key) => obj[key].val === value);
        };
        const enKey = "en";
        if (settings) {
          setClinicSettings(settings);
        }
        if (settings?.clinicDefaultLanguage) {
          const langKey = getObjectKey(
            languageMap,
            settings.clinicDefaultLanguage
          );
          i18n.changeLanguage(langKey);
          setClinicLang(langKey);
        } else {
          i18n.changeLanguage(enKey);
          setClinicLang(enKey);
        }

        if (settings?.patientDefaultLanguage)
          setPatientDefaultLang(
            getObjectKey(allLanguageMap, settings.patientDefaultLanguage)
          );
        else setPatientDefaultLang(null);
      } catch (e) {}
    },
    [
      getAccessTokenSilently,
      i18n,
      setClinicLang,
      setPatientDefaultLang,
      setClinicSettings,
    ]
  );

  const sortedAllClinicInfo = useMemo(
    () => allClinicInfo?.sort(getComparator("asc", ["name"])),
    [allClinicInfo]
  );

  return allClinicInfo.length > 0 ? (
    <>
      {/* clinic switcher button */}
      <Button
        onClick={clinicMenuClick}
        disabled={allClinicInfo.length <= 1}
        sx={{
          display: { xs: "block", sm: "inline" },
          minWidth: { xs: 0, sm: 64 },
          mx: { xs: 0, sm: 0, md: 2, lg: 4 },
          color: "#E7EBF8",
          fontSize: 20,
          ...(Boolean(menu) && {
            fontWeight: "bold",
            textDecoration: "underline",
            textUnderlineOffset: 4,
          }),
          "&:hover": {
            ...(Boolean(menu) && {
              textDecoration: "underline",
              textUnderlineOffset: 4,
            }),
          },
        }}
      >
        <div className="flex space-x-2 items-center">
          {/* <Grid item display={{ xs: "block", sm: "flex" }} alignItems={"center"}> */}
          <Apartment sx={{ color: "#E7EBF8" }} />
          <Typography
            variant="button"
            sx={{
              color: "#E7EBF8",
              display: { xs: "none", sm: "none", md: "block" },
              textTransform: "capitalize",
              fontSize: 20,
            }}
          >
            {clinicName}
          </Typography>
          {/* </Grid> */}
        </div>
      </Button>

      {/* clinic switcher menu, when open */}
      <Popover
        sx={{ mt: 4 }}
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={clinicMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: { width: "100%" },
        }}
        classes={{
          paper: "py-4 px-2",
        }}
      >
        <Grid container spacing={2} px={4} pt={1}>
          {/* mapping all clinics the user has access to */}
          {sortedAllClinicInfo.map((clinic) => (
            <Grid
              item
              xs={12}
              sm={2}
              display="flex"
              key={clinic.id}
              style={{ padding: 0 }}
            >
              <Button
                onClick={() => {
                  // Reset page for patients and exams table.
                  sessionStorage.setItem("patientsListPage", 1);
                  sessionStorage.setItem("examsListPage", 1);
                  handleClinicChange(clinic);
                }}
                sx={{
                  color: "#202338",
                  display: "block",
                  textTransform: "capitalize",
                  fontSize: { xs: 14, sm: 20 },
                  ...(clinicName === clinic.name && {
                    fontWeight: "bold",
                    textDecoration: "underline",
                    textUnderlineOffset: 4,
                  }),
                  "&:hover": {
                    ...(clinicName === clinic.name && {
                      textDecoration: "underline",
                      textUnderlineOffset: 4,
                    }),
                  },
                }}
              >
                {clinic.name}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </>
  ) : (
    <></>
  );
};

export default ClinicSwitcher;
