// Library methods
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";

// Components
import Loader from "../Loader";
import LiveStreamImage from "../LiveUpdateModal/LiveStreamImage";

// Utilities
import { getIceEndpoint, getIceServers } from "../../../services/WebRtc";
import blackout from "../../../assets/images/grayscale/blackLive.jpeg";
import {
  invokeNotifyAnswerAccepted,
  invokeSendIceCandidate,
  invokeSendOffer,
} from "../../../services/SignalR";
import {
  byteToInt16,
  byteToInt32,
  combineInt8Array,
} from "../../../utils/byteHelper";

export const WebRtcComponent = ({
  peerConnection,
  setPeerConnection,
  dataChannel,
  setDataChannel,
  connection,
  deviceId,
  deviceStage,
  imageSrc,
  setImageSrc,
  stopWebRTC,
  hmdInProximity,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const readyToGetFrame = useRef(true);
  const receivedLength = useRef(0);
  const dataByte = useRef(new Uint8Array(0));
  const dataId = useRef(0);
  const [currentConnectionState, setCurrentConnectionState] = useState(null);

  const processImageData = useCallback(
    (byte) => {
      readyToGetFrame.current = false;
      let binary = "";
      const bytes = new Uint8Array(byte);

      // Convert byte[] to Base64 string
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }

      // Display image
      setImageSrc("data:image/jpeg;base64," + btoa(binary));

      readyToGetFrame.current = true;
    },
    [setImageSrc]
  );

  const handleReceiveData = useCallback(
    (event) => {
      const receivedBytes = new Uint8Array(event.data);

      if (receivedBytes.length > 18) {
        const label = byteToInt16(receivedBytes, 0);

        if (label === 1001) {
          const dataIdReceived = byteToInt16(receivedBytes, 2);
          if (dataIdReceived !== dataId.current) receivedLength.current = 0;
          dataId.current = dataIdReceived;

          const dataLength = byteToInt32(receivedBytes, 4);
          const offset = byteToInt32(receivedBytes, 8);

          const isDesktopFrame = receivedBytes[14] !== 0 ? true : false;
          const metaByteLength = isDesktopFrame ? 24 : 15;

          if (receivedLength.current === 0)
            dataByte.current = new Uint8Array(dataLength);
          const chunkLength = receivedBytes.length - metaByteLength;

          if (offset + chunkLength <= dataByte.current.length) {
            receivedLength.current = receivedLength.current + chunkLength;
            dataByte.current = combineInt8Array(
              dataByte.current,
              receivedBytes.slice(metaByteLength, receivedBytes.length),
              offset
            );
          }

          if (readyToGetFrame) {
            console.log(receivedLength.current, " / ", dataLength);
            console.log(dataByte.current);
            if (receivedLength.current === dataLength)
              processImageData(dataByte.current);
          }
        }
      }
    },
    [dataByte, processImageData, receivedLength]
  );

  const sendIceCandidate = useCallback(
    async (event) => {
      try {
        if (event.candidate) {
          const token = await getAccessTokenSilently();
          console.log("Ice candidate found!", event.candidate);
          const iceCandidate = {
            Candidate: event.candidate.candidate,
            SdpMid: event.candidate.sdpMid,
            SdpMlineIndex: event.candidate.sdpMLineIndex,
          };
          console.log(iceCandidate);
          invokeSendIceCandidate(token, deviceId, iceCandidate, connection);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [connection, deviceId, getAccessTokenSilently]
  );

  const handleIceconnectionStateChange = useCallback(
    async (event) => {
      const currentIceConnectionState =
        event?.currentTarget?.iceConnectionState;

      if (currentIceConnectionState === "disconnected") {
        stopWebRTC();
      }
      setCurrentConnectionState(currentIceConnectionState);
    },
    [stopWebRTC]
  );

  const createWebRtcResources = useCallback(
    (iceServers) => {
      const peerConnection = new RTCPeerConnection({
        iceServers: [iceServers],
      });
      peerConnection.onicecandidate = sendIceCandidate;
      peerConnection.oniceconnectionstatechange =
        handleIceconnectionStateChange;
      setPeerConnection(peerConnection);

      const dataChannel = peerConnection.createDataChannel("VRStreaming", {
        ordered: true,
      });
      dataChannel.onmessage = handleReceiveData;
      setDataChannel(dataChannel);
    },
    [
      handleIceconnectionStateChange,
      handleReceiveData,
      sendIceCandidate,
      setDataChannel,
      setPeerConnection,
    ]
  );

  const sendOffer = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      // Send offer to the server
      const offer = await peerConnection.createOffer();
      await peerConnection.setLocalDescription(offer);

      await invokeSendOffer(token, deviceId, offer, connection);
    } catch (e) {
      console.error(e);
    }
  }, [connection, deviceId, getAccessTokenSilently, peerConnection]);

  const handleWebRtcEvents = useCallback(() => {
    // Listen for answers and ICE candidates
    connection.off("WebRtcAnswerReceived");
    connection.on("WebRtcAnswerReceived", async (response) => {
      console.log("Received answer from device:", response);
      const responseParsed = JSON.parse(response);
      const answer = JSON.parse(responseParsed.data);
      answer.type = "answer";
      await peerConnection.setRemoteDescription(
        new RTCSessionDescription(answer)
      );
      const token = await getAccessTokenSilently();
      invokeNotifyAnswerAccepted(token, deviceId, connection);
    });

    connection.off("WebRtcIceCandidateReceived");
    connection.on("WebRtcIceCandidateReceived", async (response) => {
      const responseParsed = JSON.parse(response);
      const iceCandidate = JSON.parse(responseParsed.data);
      if (!iceCandidate) return;
      const iceCandidateParsed = {
        candidate: iceCandidate.Candidate,
        sdpMLineIndex: iceCandidate.SdpMLineIndex,
        sdpMid: iceCandidate.SdpMid,
      };
      const candidate = new RTCIceCandidate(iceCandidateParsed);
      await peerConnection.addIceCandidate(candidate);
    });
  }, [connection, deviceId, getAccessTokenSilently, peerConnection]);

  const getIceServersInfo = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const res = await getIceEndpoint(token);

      if (!res?.iceEndpoint || !res?.credentials)
        throw new Error("Fatal: Receiving ICE endpoint");

      const iceServers = await getIceServers(res.iceEndpoint, res.credentials);
      return iceServers;
    } catch (e) {
      console.error(e);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (peerConnection) return;
    getIceServersInfo().then(async (iceServers) => {
      createWebRtcResources(iceServers);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (peerConnection) {
      // Listen signaling
      handleWebRtcEvents();
      sendOffer();
    }
  }, [peerConnection, handleWebRtcEvents, sendOffer]);

  useEffect(() => {
    if (currentConnectionState === "disconnected" && deviceStage) {
      getIceServersInfo().then(async (iceServers) => {
        createWebRtcResources(iceServers);
      });
      setCurrentConnectionState(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createWebRtcResources, deviceStage, getIceServersInfo]);

  return !peerConnection || currentConnectionState === "disconnected" ? (
    <Loader containerHeight="324px" />
  ) : (
    <LiveStreamImage
      src={imageSrc ?? blackout}
      alt={"stream"}
      deviceStageType={deviceStage?.stageType}
      hmdInProximity={hmdInProximity}
    />
  );
};
