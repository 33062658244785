// Library methods
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVrCardboard,
  faPen,
  faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";

// MUI Components
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

// Utilities
import { getBatteryIcon } from "../../../../utils/deviceHelper";
import { getLocalTime } from "../../../../utils/dateHelper";

const DevicesManagementCard = ({
  row,
  handleEditClick,
  handleDeviceHistoryClick,
}) => {
  // internationalization
  const { t } = useTranslation();

  // is true if the device is in use
  const isLinked = !!row?.associatedClinic?.clinic;

  // getting the exam's patient
  const associatedClinicName = row?.associatedClinic?.clinic?.name ?? "";

  // getting the device status
  const deviceStatus = useMemo(() => {
    if (row?.deviceStatus) return row.deviceStatus;

    return null;
  }, [row?.deviceStatus]);

  const convertedDeviceExpiryDate = useMemo(() => {
    const d = row?.expiryDate;
    if (d) {
      return getLocalTime(d);
    }
    return null;
  }, [row?.expiryDate]);

  const lastModifiedDate = useMemo(() => {
    const d = row?.lastModifiedDate;
    if (d) {
      return getLocalTime(d);
    }
    return null;
  }, [row?.lastModifiedDate]);

  // Extract version number with regular expression
  const appVersion = useMemo(() => {
    if (row?.applicationVersion) {
      // const regex = /.*\d/;
      const regex = /\d+(\.\d+)*/g;
      return `v${row?.applicationVersion.match(regex)}`;
    }
    return null;
  }, [row?.applicationVersion]);

  const isOutdated = useMemo(() => {
    if (row?.webserviceCompatible === false) return true;
    return false;
  }, [row?.webserviceCompatible]);

  return (
    <Card
      elevation={0}
      key={row.id}
      sx={{
        width: { xs: "115px", sm: "210px", lg: "250px" },
        // height: { xs: "185px", sm: "110px", lg: "115px" },
        borderRadius: 2,
        backgroundColor: "white",
        border: "2px solid #E2772E90",
        ...(isOutdated && {
          backgroundColor: "white",
        }),
        ...(isLinked && {
          backgroundColor: "rgba(0,0,0,0.1)",
          border: "2px solid rgba(0,0,0,0)",
        }),
        ...(isOutdated && {
          border: "2px solid #87201a",
        }),
        py: 1.25,
        px: 1.5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <CardContent sx={{ p: 0 }}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent={"space-between"}
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "flex-start" }}
              sx={{ gap: 1, width: "100%" }}
            >
              <Box
                sx={{
                  width: { sm: "43.14px", md: "43.14px", lg: "50.35px" },
                  height: { sm: "25.77px", md: "25.77px", lg: "30.21px" },
                }}
              >
                <FontAwesomeIcon
                  icon={faVrCardboard}
                  size="2x"
                  color={
                    isLinked
                      ? "rgba(0,0,0,0.2)"
                      : isOutdated
                        ? "#87201a"
                        : "#E2772E"
                  }
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography
                  noWrap
                  component="div"
                  variant="body1"
                  sx={{
                    width: { xs: "100%", sm: "80%" },
                    fontWeight: "700",
                    fontSize: { xs: "12px", sm: "14px" },
                    lineHeight: "14px",
                  }}
                >
                  {row.alias}
                </Typography>
                <Typography
                  gutterBottom
                  noWrap
                  component="div"
                  variant="body2"
                  sx={{
                    fontWeight: "400",
                    width: { xs: "100%", sm: "80%" },
                    fontSize: { xs: "11px", sm: "12px" },
                    marginBottom: 0,
                  }}
                >
                  {appVersion}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>

      <Grid container item xs={12}>
        <Grid container item xs={7} sm={6} md={7} height={45}>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            flexDirection={"row"}
            height={20}
          >
            {deviceStatus?.battery >= 0 && (
              <>
                {getBatteryIcon(deviceStatus?.battery)}
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontWeight: 400,
                    fontSize: "13px",
                    lineHeight: "13px",
                  }}
                  pl={0.2}
                >
                  {Math.floor(deviceStatus?.battery * 100)}%
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12} height={20}>
            <Typography
              noWrap
              variant="body2"
              sx={{ color: isLinked ? "rgba(0,0,0,0.4)" : "#E2772E" }}
            >
              {isLinked ? associatedClinicName : " "}
            </Typography>
          </Grid>
          {/* <Grid container item xs={8} sm={7} md={8}> */}
        </Grid>
        <Grid
          container
          item
          xs={4}
          sm={5}
          md={4}
          display="flex"
          alignItems={"center"}
          justifyContent={"center"}
          height={20}
        ></Grid>
      </Grid>
      <Grid container item xs={12} height={30}>
        <Grid item xs={12}>
          <Grid item xs={12} height={10}>
            {lastModifiedDate && (
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: { xs: 9, sm: 9.5 },
                  color: "rgba(0,0,0,0.7)",
                }}
              >
                {`${t("device_last_modified")} : ${lastModifiedDate}`}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} mt={0.5} height={10}>
            {row?.serialNumber && (
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: { xs: 9, sm: 9.5 },
                  color: "rgba(0,0,0,0.7)",
                }}
                noWrap
              >
                {`${t("device_serial_number")} : ${row.name}`}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} mt={0.5}>
            {convertedDeviceExpiryDate && (
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: { xs: 9, sm: 9.5 },
                  color: "rgba(0,0,0,0.7)",
                }}
              >
                {`${t(
                  "device_card_expiry_date"
                )} : ${convertedDeviceExpiryDate}`}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} mt={0.5}>
        <Grid container item xs={8}>
          <Grid item xs={12}>
            {row?.deviceStatus?.stage != null ? (
              <div className="flex items-center gap-1.5">
                <div className="w-2.5 h-2.5 bg-green-500 rounded-full"></div>
                <span style={{ fontSize: "0.9em" }}>{t("word_online")}</span>
              </div>
            ) : (
              <div className="flex items-center gap-1.5">
                <div className="w-2.5 h-2.5 bg-gray-400 rounded-full"></div>
                <span style={{ fontSize: "0.9em" }}>{t("word_offline")}</span>
              </div>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          xs={4}
          display="flex"
          alignItems="flex-end"
          justifyContent={{ xs: "flex-start", sm: "flex-end" }}
        >
          <Tooltip title={t("device_edit_hover")}>
            <IconButton
              size="small"
              sx={{
                backgroundColor: isLinked
                  ? "rgba(0,0,0,0.2)"
                  : isOutdated
                    ? "#87201a20"
                    : "#E2772E20",
              }}
              style={{ alignContent: "right" }}
              onClick={() => handleEditClick(row)}
            >
              <FontAwesomeIcon
                icon={faPen}
                size="sm"
                color={
                  isLinked
                    ? "rgba(0,0,0,0.2)"
                    : isOutdated
                      ? "#87201a"
                      : "#E2772E"
                }
              />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              row?.clinicsHistory?.length === 0
                ? "No device history"
                : t("device_history_hover")
            }
          >
            <IconButton
              size="small"
              sx={{
                ml: 1.5,
                backgroundColor:
                  isLinked || row?.clinicsHistory?.length === 0
                    ? "rgba(0,0,0,0.2)"
                    : isOutdated
                      ? "#87201a20"
                      : "#E2772E20",
                "&:hover": {
                  backgroundColor:
                    isLinked || row?.clinicsHistory?.length === 0
                      ? "rgba(0,0,0,0.2)"
                      : "#E2772E20",
                },
              }}
              style={{ alignContent: "right" }}
              onClick={() => handleDeviceHistoryClick(row)}
            >
              <FontAwesomeIcon
                icon={faClockRotateLeft}
                size="sm"
                color={
                  isLinked || row?.clinicsHistory?.length === 0
                    ? "rgba(0,0,0,0.2)"
                    : isOutdated
                      ? "#87201a"
                      : "#E2772E"
                }
              />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Card>
  );
};

export default DevicesManagementCard;
