const pagesCount = (array, perPage) => Math.ceil(array.length / perPage);

const itemsRendering = (array, currentPage, perPage) => {
  const currentPageIndex = currentPage - 1;
  const firstIndex = currentPageIndex * perPage;
  const endIndex = firstIndex + perPage;
  return endIndex >= array.length
    ? array.slice(firstIndex, array.length)
    : array.slice(firstIndex, endIndex);
};

export { pagesCount, itemsRendering };
