// Library methods
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

// MUI Components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Components
import DevicesManagementCard from "./DevicesManagementCard";
import EditDeviceModal from "./EditDeviceModal";
import DeviceHistoryModal from "./DeviceHistoryModal";
import SnackbarMessage from "../../../components/UI/SnackbarMessage";
import Loader from "../../../components/UI/Loader";
import PageIntro from "../../../components/UI/PageIntro";

// Utilities
import {
  assignDevice,
  unassignDevice,
  updateDevice,
  getClinicDevicesById,
} from "../../../services/Device";
import { getComparator } from "../../../utils/tableHelper";
import { unlinkExamFromDevice } from "../../../services/Device";
import { formatDateForMuiX, toLocalDateTime } from "../../../utils/dateHelper";

const DevicesManagementList = ({
  rows,
  isLoading,
  clinicsOptions,
  refetchDevices,
}) => {
  // internationalization
  const { t } = useTranslation();

  // auth0
  const { getAccessTokenSilently } = useAuth0();

  // order consts
  const order = "asc";
  const orderBy = ["alias"];

  // form field states init
  const [editDeviceClinicId, setEditDeviceClinicId] = useState("");
  const [editDeviceAlias, setEditDeviceAlias] = useState("");
  const [deviceExpiryDate, setDeviceExpiryDate] = useState(null); // device expiry date (deviceExpiryDate)
  const [clinicAssignmentExpiryDate, setClinicAssignmentExpiryDate] =
    useState(null); // license expiry date (licenseExpiryDate)
  const [selectedDevice, setSelectedDevice] = useState("");

  // state for device history modal content
  const [selectedDeviceHistory, setSelectedDeviceHistory] = useState([]);

  // state for edit device modal
  const [editDeviceModal, setEditDeviceModal] = useState(false);

  // state for device history modal
  const [deviceHistoryModal, setDeviceHistoryModal] = useState(false);

  // device edit toast states
  const [editSuccessToast, setEditSuccessToast] = useState(false);
  const [editFailureToast, setEditFailureToast] = useState(false);

  // state for the search bar
  const [searchTerm, setSearchTerm] = useState("");

  // filter change
  const onFilterChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // clear filter
  const clearSearchFilter = (event) => {
    setSearchTerm("");
  };

  // cancel device history modal
  const cancelDeviceHistory = () => {
    // close edit modal
    setDeviceHistoryModal(false);
  };

  // cancel edit device modal
  const cancelEdit = () => {
    // close edit modal
    setEditDeviceModal(false);
  };

  // handle device history click
  const handleDeviceHistoryClick = useCallback(
    async (device) => {
      try {
        const token = await getAccessTokenSilently();
        const response = await getClinicDevicesById(token, device?.id);
        if (response?.length > 0) {
          const autopopulate = async () => {
            setSelectedDeviceHistory(response);
          };
          autopopulate();
          setDeviceHistoryModal(true);
        }
      } catch (error) {
        //console.log(error);
      }
    },
    [getAccessTokenSilently]
  );

  // handle edit device click
  const handleEditClick = useCallback((device) => {
    const autopopulate = async () => {
      setEditDeviceClinicId(device.associatedClinic?.clinic?.id ?? "None");
      setEditDeviceAlias(device.alias);
      setDeviceExpiryDate(
        device?.expiryDate ? toLocalDateTime(device.expiryDate) : null
      );
      setClinicAssignmentExpiryDate(
        device?.associatedClinic?.expiryDate
          ? toLocalDateTime(device.associatedClinic.expiryDate)
          : null
      );
    };
    autopopulate();
    setSelectedDevice(device);
    setEditDeviceModal(true);
  }, []);

  // edit device info
  const editDevice = useCallback(async () => {
    const formattedDeviceExpirtyDate = new Date(deviceExpiryDate);
    const formattedClinicAssignmentExpirtyDate = new Date(
      clinicAssignmentExpiryDate
    );

    const devicePayload = {
      alias: editDeviceAlias,
      expiryDate: deviceExpiryDate
        ? formatDateForMuiX(formattedDeviceExpirtyDate.setHours(0, 0, 0, 0))
        : null,
    };
    const clinicPayload = {
      clinicId: editDeviceClinicId,
      expiryDate: clinicAssignmentExpiryDate
        ? formatDateForMuiX(
            formattedClinicAssignmentExpirtyDate.setHours(0, 0, 0, 0)
          )
        : null,
    };

    const shouldUnlinkExam =
      selectedDevice?.associatedExam &&
      editDeviceClinicId !== selectedDevice?.associatedClinic?.clinic?.id;

    // close modal
    setEditDeviceModal(false);

    // add to api
    try {
      // get token
      const token = await getAccessTokenSilently();

      const editDeviceId = selectedDevice?.id;

      await updateDevice(token, editDeviceId, devicePayload);

      // clinic assignment expiry date is interactable only when the clinic id is set (selected).
      if (!editDeviceClinicId || editDeviceClinicId === "None") {
        if (shouldUnlinkExam)
          await unlinkExamFromDevice(
            token,
            selectedDevice?.associatedClinic?.clinic?.id,
            editDeviceId
          );
        await unassignDevice(token, editDeviceId);
      } else {
        if (shouldUnlinkExam)
          await unlinkExamFromDevice(
            token,
            selectedDevice?.associatedClinic?.clinic?.id,
            editDeviceId
          );
        await assignDevice(token, editDeviceId, clinicPayload);
      }

      refetchDevices();
      setEditSuccessToast(true);
    } catch (error) {
      setEditFailureToast(true);
    }
  }, [
    clinicAssignmentExpiryDate,
    deviceExpiryDate,
    editDeviceAlias,
    editDeviceClinicId,
    getAccessTokenSilently,
    refetchDevices,
    selectedDevice?.associatedClinic?.clinic?.id,
    selectedDevice?.associatedExam,
    selectedDevice?.id,
  ]);

  const checkClinicExams = useCallback(
    () =>
      editDeviceClinicId !== selectedDevice?.associatedClinic?.clinic?.id &&
      selectedDevice?.associatedExam,
    [editDeviceClinicId, selectedDevice]
  );

  // handle on close of the device history modal
  const handleDeviceHistoryModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setDeviceHistoryModal(false);
    }
  };

  // handle on close of the edit device modal
  const handleEditModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setEditDeviceModal(false);
    }
  };

  // Filtered rows
  const filteredRows = rows
    .slice()
    .filter((row) => {
      if (
        searchTerm.length &&
        row.alias.toLowerCase().indexOf(searchTerm.toLowerCase()) < 0 &&
        row.name.toLowerCase().indexOf(searchTerm.toLowerCase()) < 0 &&
        String(row?.applicationVersion)?.indexOf(searchTerm.toLowerCase()) <
          0 &&
        (row?.associatedClinic?.clinic?.name ?? "")
          ?.toLowerCase()
          .indexOf(searchTerm.toLowerCase()) < 0
      )
        return false;
      return true;
    })
    .map((row) => ({
      ...row,
      linkedClinic: row.associatedClinic?.clinic?.name || "",
    }))
    .sort(getComparator(order, orderBy));

  const rowsToRender = filteredRows;

  const ListElement = () =>
    isLoading ? (
      <Loader containerHeight="19.5vh" />
    ) : (
      <Grid container mt={3} spacing={{ xs: 2, sm: 6, md: 3 }} mb={2}>
        {rowsToRender.length > 0 ? (
          <>
            {rowsToRender.map((row) => {
              return (
                <Grid item xs={6} sm={4} md={3} key={row.id}>
                  <DevicesManagementCard
                    key={row.id}
                    row={row}
                    handleEditClick={handleEditClick}
                    handleDeviceHistoryClick={handleDeviceHistoryClick}
                  />
                </Grid>
              );
            })}
          </>
        ) : (
          <Grid item xs={12}>
            <Box my={8} display="flex" justifyContent="center">
              <Typography
                noWrap
                variant="body1"
                sx={{ textTransform: "capitalize" }}
                color="text.secondary"
              >
                {t("word_no_devices")}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    );

  return (
    <>
      <PageIntro
        pageTitle={t("devices_management_title")}
        showAddButton={false}
        showDeleteButton={false}
        onFilterChange={onFilterChange}
        clearSearchFilter={clearSearchFilter}
        desktopMarginBottom={2}
      />

      {ListElement()}

      {/* Device history modal */}
      <DeviceHistoryModal
        open={deviceHistoryModal}
        deviceHistory={selectedDeviceHistory}
        onClose={handleDeviceHistoryModalClose}
        onCancel={cancelDeviceHistory}
      />

      {/* Edit device modal */}
      <EditDeviceModal
        open={editDeviceModal}
        deviceClinic={editDeviceClinicId}
        deviceAlias={editDeviceAlias}
        setDeviceClinic={setEditDeviceClinicId}
        setDeviceAlias={setEditDeviceAlias}
        onClose={handleEditModalClose}
        clinicsOptions={clinicsOptions}
        onConfirm={() => editDevice()}
        onCancel={cancelEdit}
        checkClinicExams={checkClinicExams}
        deviceExpiryDate={deviceExpiryDate}
        setDeviceExpiryDate={setDeviceExpiryDate}
        clinicAssignmentExpiryDate={clinicAssignmentExpiryDate}
        setClinicAssignmentExpiryDate={setClinicAssignmentExpiryDate}
      />

      {/* Device edit success/failure toasts */}
      <SnackbarMessage
        open={editSuccessToast}
        onClose={() => setEditSuccessToast(false)}
        success
        text={t("device_edit_success")}
      />
      <SnackbarMessage
        open={editFailureToast}
        onClose={() => setEditFailureToast(false)}
        text={t("device_edit_error")}
      />
    </>
  );
};

export default DevicesManagementList;
