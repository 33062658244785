// Utilities
import { getImageInfo } from "../../../../../../utils/examReportsHelper";

// Styles
import styles from "./index.module.css";

const QuadrantBuilder = ({
  gridColumns = 14,
  gridRows = 14,
  spotHeight = 10.5, // in px
  spotWidth = 10.5, // in px
  gridGap = 0, // in px
  gridValues = [], // should be an array, cointaining an array of items per row (based on the informed gridColumns and gridRows)
  mirrorHorizontally = false,
  mirrorVertically = false,
}) => {
  const shouldMirrorHorizontally = mirrorHorizontally && !mirrorVertically;
  const shouldMirrorVerically = !mirrorHorizontally && mirrorVertically;
  const shouldMirrorDiagonally = mirrorHorizontally && mirrorVertically;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${gridColumns}, ${spotWidth}px)`,
        gridTemplateRows: `repeat(${gridRows}, ${spotHeight}px)`,
        backgroundColor: "white",
        height: `${gridRows * spotHeight}px`,
        width: `${gridColumns * spotWidth}px`,
        gridGap,
        /* mirror horizontally */
        ...(shouldMirrorHorizontally && { transform: "scaleX(-1)" }),
        /* mirror vertically */
        ...(shouldMirrorVerically && { transform: "scaleY(-1)" }),
        /* mirror both horizontally and vertically */
        ...(shouldMirrorDiagonally && { transform: "scaleX(-1) scaleY(-1)" }),
      }}
    >
      {gridValues.map((row, indexGrid) => {
        return row.map((item, indexRow) => {
          const { imageSrc, alt } = getImageInfo(item);
          return (
            <img
              key={`${indexGrid}${indexRow}`}
              src={imageSrc}
              alt={alt}
              className={styles.imageRender}
              width={spotWidth}
              height={spotHeight}
            />
          );
        });
      })}
    </div>
  );
};

export default QuadrantBuilder;
