// Library methods
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { throttle } from "lodash";

// MUI Components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Components
import AddPatientModal from "./AddPatientModal";
import DeletePatientModal from "./DeletePatientModal";
import EditPatientModal from "./EditPatientModal";
import UploadPatientsModal from "./UploadPatientsModal";
import PatientCard from "./PatientCard";
import ListHeader from "../../../components/UI/ListHeader";
import PaginationWrapper from "../../../components/UI/PaginationWrapper";
import PageIntro from "../../../components/UI/PageIntro";
import SnackbarMessage from "../../../components/UI/SnackbarMessage";
import Loader from "../../../components/UI/Loader";

// Utilities
import {
  addPatient,
  deletePatient,
  getPatientById,
  updatePatient,
} from "../../../services/Patient";
import { getComparator } from "../../../utils/tableHelper";
import { localizeDate } from "../../../utils/localeHelper";
import { itemsRendering, pagesCount } from "../../../utils/paginationHelper";
import { formatDateForMuiX, toLocalDateTime } from "../../../utils/dateHelper";
import useClinic from "../../../hooks/useClinic";
import { useWindowDimensions } from "../../../contexts/WindowSizeContext";
import { allLanguageMap, languageMap } from "../../../i18n/languages";

const PatientsList = React.memo(
  ({
    isLoading,
    rows,
    setRows,
    page,
    setPage,
    selectedForDelete,
    setSelectedForDelete,
    setSelectedPatients,
    selectedPatients,
    heightExceptListsContainer,
    fromReport,
  }) => {
    // internationalization
    const { t } = useTranslation();

    // auth0
    const { getAccessTokenSilently } = useAuth0();

    // clinic context
    const { clinicId, clinicLang, patientDefaultLang } = useClinic();

    const orderByPatientName = ["firstName", "lastName"];

    // states init
    const [order, setOrder] = useState("asc");
    const [orderByOptions, setOrderByOptions] = useState(orderByPatientName);
    const [searchTerm, setSearchTerm] = useState("");

    // form field states init
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [healthCareId, setHealthCareId] = useState("");
    const [birthDate, setBirthDate] = useState(null);
    const [birthDateError, setBirthDateError] = useState(0);
    const [sex, setSex] = useState({ name: "", val: "" });
    const [language, setLanguage] = useState({});
    const [existingPatient, setExistingPatient] = useState(null);

    const [editPatientId, setEditPatientId] = useState("");
    const [editFirstName, setEditFirstName] = useState("");
    const [editLastName, setEditLastName] = useState("");
    const [editBirthDate, setEditBirthDate] = useState("");
    const [editSex, setEditSex] = useState({ name: "", val: "" });
    const [editHealthCareId, setEditHealthCareId] = useState("");
    const [editLanguage, setEditLanguage] = useState({});
    const [isActive, setIsActive] = useState("");

    // state to keep the data read from the bulk upload csv
    const [usersToUpload, setUsersToUpload] = useState([]);

    // state for add and delete patients modals
    const [addModal, setAddModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [bulkUploadModal, setBulkUploadModal] = useState(false);

    // patient add toast states
    const [successToast, setSuccessToast] = useState(false);
    const [failureToast, setFailureToast] = useState(false);

    // patient delete toast states
    const [deleteSuccessToast, setDeleteSuccessToast] = useState(false);
    const [deleteFailureToast, setDeleteFailureToast] = useState(false);

    // patient edit toast states
    const [editSuccessToast, setEditSuccessToast] = useState(false);
    const [editFailureToast, setEditFailureToast] = useState(false);

    // patient bulk upload toast states
    const [bulkUploadSuccessToast, setBulkUploadSuccessToast] = useState(false);
    const [bulkUploadFailureToast, setBulkUploadFailureToast] = useState(false);

    // patientCard height / props.HeightExceptListContainer
    const [maxNumOfRows, setMaxNumOfRows] = useState(1);
    const patientCardRef = useRef(null);
    const [patientCardHeight, setPatientCardHeight] = useState(0);

    const [clinicDefaultLanguage, setClinicDefaultLanguage] = useState(null);

    // dynamic window dimentions with context
    const { height } = useWindowDimensions();

    // head rows
    const headCells = [
      {
        id: "name",
        label: t("patients_column_name"),
        gridSize: 3,
        orderUsing: orderByPatientName,
      },
      {
        id: "age",
        label: t("patients_column_age"),
        gridSize: 1.5,
        orderUsing: ["dateOfBirth", ...orderByPatientName],
      },
      {
        id: "sex",
        label: t("patients_column_sex"),
        gridSize: 2,
        orderUsing: ["sex", ...orderByPatientName],
      },
      {
        id: "language",
        label: t("patients_column_language"),
        gridSize: 2.5,
        orderUsing: ["language", orderByPatientName],
      },
      {
        id: "next",
        label: t("exams_column_scheduled_date"),
        gridSize: 3,
        orderUsing: ["nextScheduledExamDate", orderByPatientName],
      },
    ];
    const extraCells = [
      {
        id: "manage",
        label: "",
        gridSize: 1,
      },
    ];

    const genderData = [
      { name: t("gender_male"), val: "Male" },
      { name: t("gender_female"), val: "Female" },
      {
        name: t("gender_prefer_not_to_say"),
        val: "Undefined",
      },
    ];

    // Filtered rows
    const filteredRows = useMemo(() => {
      return rows
        .filter((row) => {
          if (
            searchTerm.length &&
            `${row.firstName} ${row.lastName}`
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase()) < 0 &&
            String(row.age).toLowerCase().indexOf(searchTerm.toLowerCase()) <
              0 &&
            localizeDate(row?.creationDate)
              .toISOString()
              .slice(0, 10)
              .indexOf(searchTerm.toLowerCase()) < 0 &&
            (row?.healthCareId || "")
              .toLowerCase()
              ?.indexOf(searchTerm.toLowerCase()) < 0
          )
            return false;
          return true;
        })
        .sort(getComparator(order, orderByOptions));
    }, [order, orderByOptions, rows, searchTerm]);

    const numsOfRenderedRows = Math.max(maxNumOfRows, 6);
    const rowsToRender = useMemo(() => {
      return itemsRendering(filteredRows, page, numsOfRenderedRows);
    }, [filteredRows, numsOfRenderedRows, page]);

    useEffect(() => {
      // Find the index of the selectedUser in the filteredRows array
      const selectedIndex = filteredRows.findIndex(
        (row) => row.id === selectedPatients?.[0]
      );
      // Calculate the page number
      const selectedPatientPage =
        selectedIndex >= 0
          ? Math.ceil((selectedIndex + 1) / numsOfRenderedRows)
          : 1;
      setPage(selectedPatientPage);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredRows, numsOfRenderedRows, selectedPatients?.[0], setPage]);

    const sortedLanguageList = useMemo(() => {
      const priorityKeys = ["en", "fr"];
      const priorityLanguages = [];
      const otherLanguages = [];

      Object.entries(allLanguageMap).forEach(([key, value]) => {
        const formattedValue = { ...value };
        if (priorityKeys.includes(key)) {
          formattedValue.name = t(value.name);
          priorityLanguages.push(formattedValue);
        } else {
          formattedValue.name = t(value.name);
          otherLanguages.push(formattedValue);
        }
      });
      const res = [
        ...priorityLanguages,
        ...otherLanguages.sort(getComparator("asc", ["name"])),
      ];
      return res;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, clinicId]);

    // method to handle the card click
    const handleCardClick = useCallback(
      (event, id) => {
        setSelectedPatients([id]);
        setIsActive(id);
      },
      [setSelectedPatients]
    );

    // add a patient
    const insertPatient = useCallback(async () => {
      const formattedBirthDate = new Date(birthDate);
      formatDateForMuiX(formattedBirthDate.setHours(0, 0, 0, 0));
      const newRow = {
        firstName: firstName,
        healthCareId: healthCareId,
        lastName: lastName,
        dateOfBirth: formattedBirthDate,
        sex: sex?.val,
        language: language?.val ?? "English",
      };

      // close modal
      setAddModal(false);

      // add to api
      try {
        // get token
        const token = await getAccessTokenSilently();
        const res = await addPatient(token, clinicId, newRow);

        // add to table
        setRows([...rows, res]);

        // auto select created patient
        handleCardClick(undefined, res.id);

        // success toast
        setSuccessToast(true);
      } catch (error) {
        // failure toast
        setFailureToast(true);
      }

      // clear form
      clearForm();
    }, [
      firstName,
      healthCareId,
      lastName,
      birthDate,
      sex,
      language,
      getAccessTokenSilently,
      clinicId,
      setRows,
      rows,
      handleCardClick,
    ]);

    const clearForm = () => {
      // clear form
      setFirstName("");
      setLastName("");
      setHealthCareId("");
      setBirthDate(null);
      setSex({ name: "", val: "" });
      setLanguage("");
      setExistingPatient(null);
      setBirthDateError(0);
    };
    // cancel add patient modal
    const cancelAdd = () => {
      // close add modal
      setAddModal(false);

      // clear form
      clearForm();
    };

    // cancel edit patient modal
    const cancelEdit = () => {
      // close edit modal
      setEditModal(false);
      clearForm();
    };

    // cancel bulk upload patients modal
    const cancelBulkUpload = () => {
      // close bulk upload modal
      setBulkUploadModal(false);

      // clear list of users to upload
      setUsersToUpload([]);
    };

    // delete patients selected
    const deletePatients = async () => {
      // close delete Modal
      setDeleteModal(false);

      const deletePromises = selectedForDelete.map(async (patientId) => {
        const token = await getAccessTokenSilently();
        await deletePatient(token, clinicId, patientId);
      });

      try {
        await Promise.all(deletePromises);
        setDeleteSuccessToast(true);
      } catch (error) {
        setDeleteFailureToast(true);
      }

      // delete from table
      const newRows = rows.filter(
        (patient) => !selectedForDelete.includes(patient.id)
      );
      setRows([...newRows]);

      // clear selected
      setSelectedForDelete([]);
    };

    // add patient info
    const editPatient = useCallback(async () => {
      const formattedBirthDate = new Date(editBirthDate);
      formatDateForMuiX(formattedBirthDate.setHours(0, 0, 0, 0));
      const updateRow = {
        firstName: editFirstName,
        lastName: editLastName,
        dateOfBirth: formattedBirthDate,
        sex: editSex?.val,
        healthCareId: editHealthCareId,
        language: editLanguage?.val ?? "English",
      };

      // close modal
      setEditModal(false);

      // add to api
      try {
        // get token
        const token = await getAccessTokenSilently();
        await updatePatient(token, clinicId, editPatientId, updateRow);
        const newPatientData = await getPatientById(
          token,
          clinicId,
          editPatientId
        );
        setEditSuccessToast(true);
        console.log(newPatientData);
        const newRows = [...rows];
        const index = rows.findIndex((patient) => patient.id === editPatientId);
        newRows[index] = { ...newRows[index], ...newPatientData };
        // edit row on the table
        setRows(newRows);
        clearForm();
      } catch (error) {
        setEditFailureToast(true);
      }
    }, [
      editBirthDate,
      editFirstName,
      editLastName,
      editSex,
      editPatientId,
      editLanguage,
      editHealthCareId,
      clinicId,
      rows,
      setRows,
      getAccessTokenSilently,
    ]);

    // adds a bulk of patients
    const insertMultiplePatients = useCallback(async () => {
      // close modal
      setBulkUploadModal(false);

      // send requests to api
      try {
        // get token
        const token = await getAccessTokenSilently();

        const promises = usersToUpload.map((user) =>
          addPatient(token, clinicId, user)
        );
        const results = await Promise.allSettled(promises);
        const usersInfo = results.map((result) => ({ ...result.value }));

        // add to table
        setRows([...rows, ...usersInfo]);

        // success toast
        setBulkUploadSuccessToast(true);
      } catch (error) {
        // failure toast
        setBulkUploadFailureToast(true);
      }

      // clear list of users to upload
      setUsersToUpload([]);
    }, [getAccessTokenSilently, clinicId, usersToUpload, rows, setRows]);

    // handle on close of add patient modal
    const handleAddClose = (event, reason) => {
      if (reason !== "backdropClick") {
        setAddModal(false);
        setExistingPatient(null);
        setBirthDateError(0);
      }
    };

    // handle on close of delete patient modal
    const handleDeleteClose = (event, reason) => {
      if (reason !== "backdropClick") {
        setDeleteModal(false);
      }
    };

    // handle on close of edit patient modal
    const handleEditClose = (event, reason) => {
      if (reason !== "backdropClick") {
        setEditModal(false);
        setExistingPatient(null);
        setBirthDateError(0);
      }
    };

    const handleConfirmNavigation = useCallback(() => {
      if (existingPatient) {
        handleAddClose();
        handleEditClose();
        clearForm();
        setSelectedPatients([existingPatient.id]);
        setIsActive(existingPatient.id);
      }
    }, [existingPatient, setSelectedPatients]);

    // handle on close of bulk upload patients modal
    const handleBulkUploadClose = (event, reason) => {
      if (reason !== "backdropClick") {
        setBulkUploadModal(false);
      }
    };

    // filter change
    const onFilterChange = (event) => {
      if (page > 1) setPage(1);
      setSearchTerm(event.target.value);
    };

    // clear filter
    const clearSearchFilter = (event) => {
      setSearchTerm("");
    };

    // pagination change
    const onPaginationChange = (event, value) => {
      setPage(value);
    };

    // handle sort Request (it can now order considering two fields)
    const handleRequestSort = (event, property) => {
      const isAsc =
        orderByOptions.length &&
        orderByOptions[0] === property[0] &&
        order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderByOptions(property);
    };

    // handles select all
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = filteredRows.map((n) => n.id);
        setSelectedForDelete(newSelecteds);
        return;
      }
      setSelectedForDelete([]);
    };

    useEffect(() => {
      if (selectedPatients) {
        setIsActive(selectedPatients[0]);
      }
    }, [selectedPatients]);

    useEffect(() => {
      const language =
        patientDefaultLang && allLanguageMap[patientDefaultLang]
          ? allLanguageMap[patientDefaultLang]
          : clinicLang && languageMap[clinicLang]
            ? languageMap[clinicLang]
            : { name: t("language_english"), val: "English" };
      setLanguage(language);
      setClinicDefaultLanguage(language);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clinicId, clinicLang, patientDefaultLang]);

    useEffect(() => {
      if (addModal) {
        setLanguage(clinicDefaultLanguage);
      }
    }, [addModal, clinicDefaultLanguage]);

    // handle checkbox clicks
    const handleCheckboxClick = (event, id) => {
      const selectedIndex = selectedForDelete.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedForDelete, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedForDelete.slice(1));
      } else if (selectedIndex === selectedForDelete.length - 1) {
        newSelected = newSelected.concat(selectedForDelete.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedForDelete.slice(0, selectedIndex),
          selectedForDelete.slice(selectedIndex + 1)
        );
      }

      setSelectedForDelete(newSelected);
    };

    // handle edit button click on patient card
    const handleEditClick = useCallback(
      (patient) => {
        setEditPatientId(patient.id);
        setEditFirstName(patient.firstName);
        setEditLastName(patient.lastName);
        setEditBirthDate(toLocalDateTime(patient.dateOfBirth));
        setEditHealthCareId(patient.healthCareId);
        let lang = null;
        Object.keys(allLanguageMap).forEach((key) => {
          if (allLanguageMap[key].val === patient.language) {
            lang = allLanguageMap[key];
          }
        });
        setEditLanguage(lang);
        const translation = {
          Male: t("gender_male"),
          Female: t("gender_female"),
          Undefined: t("gender_prefer_not_to_say"),
        };
        setEditSex({ name: translation[patient.sex], val: patient.sex });

        setEditModal(true);
      },
      [t]
    );

    // checks if is selected
    const isSelected = (id) => selectedForDelete.indexOf(id) !== -1;

    // checks if the patient name already exists or not
    const checkPatientName = useCallback(
      (first, last) => {
        const exists = rows?.find(
          (patient) => patient.firstName === first && patient.lastName === last
        );
        if (exists) setExistingPatient(exists);
      },
      [rows]
    );

    // calculate the number of rendered rows
    useEffect(() => {
      if (patientCardRef.current) {
        const throttledFunc = throttle(() => {
          setPatientCardHeight(patientCardRef.current.offsetHeight);
        }, 1000);
        throttledFunc();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientCardRef.current, patientCardHeight]);

    useEffect(() => {
      if (height && patientCardHeight) {
        // 32: padding of the row *2
        setMaxNumOfRows(
          Math.floor(
            (height - heightExceptListsContainer - patientCardHeight * 2) /
              (patientCardHeight + 32)
          )
        );
        const pageCount = pagesCount(filteredRows, Math.max(maxNumOfRows, 6));
        if (pageCount < page) setPage(pageCount);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxNumOfRows, page, patientCardHeight, height]);

    const ListElement = () =>
      isLoading ? (
        <Loader containerHeight="30vh" />
      ) : (
        <Grid container mt={3}>
          <Grid item xs={12}>
            <ListHeader
              headCells={headCells}
              numSelected={selectedForDelete.length}
              order={order}
              orderBy={orderByOptions?.[0] ?? "firstName"}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredRows.length}
              containerPaddingX={0}
              extraCells={extraCells}
            />
            {rowsToRender.length > 0 ? (
              <Box mt={1}>
                {rowsToRender.map((row) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <PatientCard
                      key={row.id}
                      row={row}
                      isSelected={isItemSelected}
                      headCells={headCells}
                      handleCheckboxClick={handleCheckboxClick}
                      handleEditClick={handleEditClick}
                      handleCardClick={handleCardClick}
                      isActive={isActive}
                      ref={patientCardRef}
                    />
                  );
                })}
              </Box>
            ) : (
              <Box my={8} display="flex" justifyContent="center">
                <Typography
                  noWrap
                  variant="body1"
                  sx={{ textTransform: "capitalize" }}
                  color="text.secondary"
                >
                  {t("word_no_patients")}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      );

    // check the page (if accessing through the dashboard page, should find the correct index for the patient list)
    useEffect(() => {
      if (fromReport) {
        const rowsLength = Math.max(maxNumOfRows, 6);
        const patientId = selectedPatients[0];
        const patientIndex =
          filteredRows.findIndex((row) => row.id === patientId) + 1;

        if (patientIndex < rowsLength) setPage(1);
        else {
          const selectedPatientsPage = Math.ceil(patientIndex / rowsLength);
          setPage(selectedPatientsPage);
        }
      }
    }, [
      filteredRows,
      fromReport,
      maxNumOfRows,
      rows,
      selectedPatients,
      setPage,
    ]);

    return (
      <>
        <PageIntro
          pageTitle={t("patients_title")}
          // addButtonText={t("patients_create_patient")}
          addButtonOnClick={() => setAddModal(true)}
          bulkUploadButtonText={t("patients_upload_bulk_patients")}
          bulkUploadButtonOnClick={() => setBulkUploadModal(true)}
          deleteButtonOnClick={() => setDeleteModal(true)}
          onFilterChange={onFilterChange}
          clearSearchFilter={clearSearchFilter}
          selectedRows={selectedForDelete}
          addButtonWidth={300}
          bulkUploadButtonWidth={300}
          showBulkUploadButton={false}
        />
        {ListElement()}
        {rows?.length > rowsToRender?.length && (
          <PaginationWrapper
            page={page}
            count={pagesCount(filteredRows, Math.max(maxNumOfRows, 6))}
            onChange={onPaginationChange}
          />
        )}

        {/* Add patient modal */}
        <AddPatientModal
          open={addModal}
          onClose={handleAddClose}
          onConfirm={() => insertPatient()}
          onCancel={() => cancelAdd()}
          firstName={firstName}
          lastName={lastName}
          healthCareId={healthCareId}
          birthDate={birthDate}
          birthDateError={birthDateError}
          sex={sex}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setHealthCareId={setHealthCareId}
          setBirthDate={setBirthDate}
          setBirthDateError={setBirthDateError}
          setSex={setSex}
          language={language}
          setLanguage={setLanguage}
          checkPatientName={checkPatientName}
          existingPatient={existingPatient}
          setExistingPatient={setExistingPatient}
          onConfirmNavigation={handleConfirmNavigation}
          sortedLanguageList={sortedLanguageList}
          genderData={genderData}
        />

        {/* Delete patient modal */}
        <DeletePatientModal
          open={deleteModal}
          onClose={handleDeleteClose}
          onConfirm={() => deletePatients()}
          onCancel={() => setDeleteModal(false)}
        />

        {/* Edit patient modal */}
        <EditPatientModal
          open={editModal}
          onClose={handleEditClose}
          onConfirm={() => editPatient()}
          onCancel={() => cancelEdit()}
          firstName={editFirstName}
          lastName={editLastName}
          birthDate={editBirthDate}
          birthDateError={birthDateError}
          sex={editSex}
          healthCareId={editHealthCareId}
          setFirstName={setEditFirstName}
          setLastName={setEditLastName}
          setBirthDate={setEditBirthDate}
          setBirthDateError={setBirthDateError}
          setHealthCareId={setEditHealthCareId}
          setSex={setEditSex}
          language={editLanguage}
          setLanguage={setEditLanguage}
          checkPatientName={checkPatientName}
          clinicDefaultLanguage={clinicDefaultLanguage}
          sortedLanguageList={sortedLanguageList}
          setExistingPatient={setExistingPatient}
          genderData={genderData}
        />

        {/* Upload patients modal */}
        <UploadPatientsModal
          open={bulkUploadModal}
          onClose={handleBulkUploadClose}
          onConfirm={() => insertMultiplePatients()}
          onCancel={() => cancelBulkUpload()}
          usersToUpload={usersToUpload}
          setUsersToUpload={setUsersToUpload}
        />

        {/* Add patient success/failure toasts */}
        <SnackbarMessage
          open={successToast}
          onClose={() => setSuccessToast(false)}
          success
          text={t("patients_add_success")}
        />
        <SnackbarMessage
          open={failureToast}
          onClose={() => setFailureToast(false)}
          text={t("patients_add_error")}
        />

        {/* Delete patient success/failure toasts */}
        <SnackbarMessage
          open={deleteSuccessToast}
          onClose={() => setDeleteSuccessToast(false)}
          success
          text={t("patients_delete_success")}
        />
        <SnackbarMessage
          open={deleteFailureToast}
          onClose={() => setDeleteFailureToast(false)}
          text={t("patients_delete_error")}
        />

        {/* edit patient success/failure toasts */}
        <SnackbarMessage
          open={editSuccessToast}
          onClose={() => setEditSuccessToast(false)}
          success
          text={t("patients_edit_success")}
        />
        <SnackbarMessage
          open={editFailureToast}
          onClose={() => setEditFailureToast(false)}
          text={t("patients_edit_error")}
        />

        {/* bulk upload patients success/failure toasts */}
        <SnackbarMessage
          open={bulkUploadSuccessToast}
          onClose={() => setBulkUploadSuccessToast(false)}
          success
          text={t("patients_bulk_upload_success")}
        />
        <SnackbarMessage
          open={bulkUploadFailureToast}
          onClose={() => setBulkUploadFailureToast(false)}
          text={t("patients_bulk_upload_error")}
        />
      </>
    );
  }
);

export default PatientsList;
