// Library methods
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Components
import Navbar from "../../components/UI/Navbar";

// Styles
import { PageContainerWrapper } from "../../styles/muiStylesHelper";

// Utilities
import useClinic from "../../hooks/useClinic";

// invalid page for users without a clinic
export default function Invalid() {
  // routing
  const navigate = useNavigate();

  // clinic context
  const { clinicName } = useClinic();

  // auth
  const { isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (clinicName) navigate("/patients");
  }, [isLoading, isAuthenticated, clinicName, navigate]);

  const errorMessage = (
    <>
      <Typography variant="h5">
        Your clinic information could not be retrieved, or your account is not
        attached to any clinic.
      </Typography>
      <Typography variant="h5">
        Please try logging off and logging in again. If the issue persists,
        please contact us at <b>asarhan@retinalogik.ca</b>.
      </Typography>
    </>
  );

  const errorText = (
    <Typography variant="h3" mb={4}>
      Error!
    </Typography>
  );

  return (
    <div>
      <Navbar canReachPages={false} clickableLogo={false} />
      <Box
        sx={() => PageContainerWrapper()}
        px={{ xs: 2, sm: 4, md: 8, lg: 12 }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          pt={10}
        >
          {errorText}
          {errorMessage}
        </Box>
      </Box>
    </div>
  );
}
