import { useMemo } from "react";

// This hooks is to handle live data and report data
export const useLiveReportData = (data) => {
  const stats = data?.stats;
  const falsePositivePercentage = useMemo(
    () =>
      !isNaN(stats?.falsePositivePercentage)
        ? `${Math.floor(stats?.falsePositivePercentage * 100)}%`
        : "-",
    [stats?.falsePositivePercentage]
  );
  const falseNegative = useMemo(
    () =>
      !isNaN(stats?.falseNegativeMisses) && !isNaN(stats?.falseNegativeCount)
        ? `${stats?.falseNegativeMisses}/${stats?.falseNegativeCount}`
        : "-",
    [stats?.falseNegativeCount, stats?.falseNegativeMisses]
  );

  const fixationLossPercentage = useMemo(
    () =>
      !isNaN(stats?.fixationLossPercentage)
        ? `${Math.floor(stats?.fixationLossPercentage * 100)}%`
        : "-",
    [stats?.fixationLossPercentage]
  );

  return { falsePositivePercentage, falseNegative, fixationLossPercentage };
};
